function now() {
  return new Date();
}

function timeToUnix(time) {
  return new Date(`1970-01-01T${time}Z`).getTime() / 1000;
}

// const months = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December",
// ];

// function formatDate(dateString) {
//   const datetime = new Date(dateString);

//   let formattedDate = "";

//   const date = datetime.getDate();
//   const month = datetime.getMonth();
// }

// formatDate("2021-12-21");

export default {
  now,
  timeToUnix,
};
