import React from "react";
import MethodologistNavBar from "../../../../../_artifact/components/methodologist/header";
import { Input, Space, Tabs, Row, Col, Layout } from "antd";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Loader from "../../../../../_artifact/components/methodologist/loader";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import "antd/dist/antd.css";
import { fetchTestResults } from "../../_redux/results";
import Wrapper from "../../../../../_artifact/components/methodologist/wrapper";
import "../../../../../assests/styles/style.scss";
import {
  columns,
  data,
  testResultsDummy
} from "../../../../../_artifact/helpers/tableHelper";
import MethodologistTable from "../../../../../_artifact/components/methodologist/table/index";
import marsLogo from "../../../../../assests/images/bell-icon.svg";
import bellIcon from "../../../../../assests/images/bell-icon.svg";

const { Search } = Input;

export default function Tresults({ history }) {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tabFilter, setTabFilter] = useState("all");
  const [height, setHeight] = useState(0);
  const { TabPane } = Tabs;
  const { state } = useSelector((state) => ({ state }), shallowEqual);

  useEffect(() => {
    dispatch(fetchTestResults());
    // console.log("Results");
    let tableHeight = document.querySelector("header").offsetHeight;
    // console.log(window.innerHeight - tableHeight);s
    setHeight(window.innerHeight - tableHeight - 200);
  }, []);

  useEffect(() => {
    // console.log("TabFilter", tabFilter);
  }, [tabFilter]);

  function callback(key) {
    setTabFilter(key);
  }

  const handleSearch = (selectedKeys) => {
    setSearchText(selectedKeys[0]);
  };

  const Demo = () => (
    <Tabs
      defaultActiveKey={tabFilter}
      animated={true}
      onChange={(activeKey) => {
        callback(activeKey);
      }}
    >
      <TabPane tab="All" key="all"></TabPane>
      <TabPane tab="Live Tests" key="live"></TabPane>
      <TabPane tab="To be reviewed" key="review"></TabPane>
      <TabPane tab="Previous Tests" key="previous"></TabPane>
    </Tabs>
  );

  return (
    <>
      <h2>Test Results</h2>
      <Row style={{ marginBottom: "0.5%" }}>
        <Demo />
      </Row>
      <Row style={{ marginBottom: "1%" }}>
        <Space direction="vertical">
          <Search
            placeholder="Search by test code or date"
            allowClear
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setSelectedKeys(e.target.value ? [e.target.value] : []);
            }}
            onSearch={() => handleSearch(selectedKeys)}
            onPressEnter={() => handleSearch(selectedKeys)}
            style={{ width: 304 }}
          />
        </Space>
      </Row>
      <Loader loading={state.testresults.loader}>
        <MethodologistTable
          history={history}
          columns={columns}
          rowIdentifier={"test_id"}
          hideCheck={true}
          hideComment={true}
          data={state.testresults.resultsdata}
          search={searchText}
          pagination={false}
          enableRowClick={true}
          // testClick={props.testClick}
          tabFilter={tabFilter}
          tabField={"test_status_type"}
          filterCurrent={searchText}
          height={height}
          filterKey={"test_id"}
          filterKey2={"start_date"}
          className={"row-cursor-pointer"}
        />
      </Loader>
    </>
  );
}
