import React, { useEffect, useRef } from "react";
import { Link, NavLink, Prompt, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { translate } from "../_artifact/i18n/Artifacti18n";
import { trackingLog } from "./modules/Tracking/_redux/tracking";
import { format } from "date-fns";

function Navbar({ intl }) {
  const location = useLocation();
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const user = state?.auth?.user;
  const { start_time } = state?.pets?.testDuration;
  const { selectedTest } = state?.pets;
  const chkUserGoBack = useRef(false);
  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );
  const chkPath = useRef("");
  const backPath = useRef("");
  const dispatch = useDispatch();
  const ar = [
    "/testing/timerstart",
    "/testing/timer",
    "/testing/cameras/post",
    "/testing/questionnaire",
  ];
  const testPath = [
    "/testing/timerstart",
    "/testing/timer",
    "/testing/cameras/post",
    "/testing/questionnaire",
    "/testing/finish",
  ];
  const isTesting = (pathname) => ar.includes(pathname);

  const isTestingFinish = (pathname) => pathname === "/testing/finish";

  const isPrompt = (pathname) =>
    (isTesting(location.pathname) &&
      !isTesting(pathname) &&
      !isTestingFinish(pathname)) ||
    (isTestingFinish(location.pathname) && start_time !== null);

  useEffect(() => {
    chkPath.current = location.pathname;
    chkUserGoBack.current = false;
    return () => {
      if (chkUserGoBack.current === true && backPath.current) {
        dispatch(
          trackingLog({
            ...trackingPayload,
            react_log:
              "User have navigated after starting test and path from " +
              chkPath.current +
              " to " +
              backPath.current +
              " page on Date : " +
              format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          })
        );
      }
    };
  }, [location]);

  return (
    <header className="header">
      <div className="header-inner">
        <Prompt
          // when={true}
          message={({ pathname }, action) => {
            if (!testPath.includes(pathname) && ar.includes(chkPath.current)) {
              backPath.current = pathname + " " + action;
              chkUserGoBack.current = true;
            } else {
              chkUserGoBack.current = false;
            }
            return selectedTest?.test_schedule_id && isPrompt(pathname)
              ? translate("TESTING.WARNING.MISSED_TEST", intl)
              : true;
          }}
        />
        <div className="logo desktop-only">
          <Link to="/">
            {/* <img src="/assets/images/logo.svg" alt="logo" /> */}
            <img src="/assets/images/cih_logo.png" width="40" alt="" />
          </Link>
        </div>
        <ul className="nav">
          <li className="desktop-only">
            <NavLink to="/home">
              <i className="icon-test mobile-only"></i>
              <span>{translate("APP.GENERAL.HOME", intl)}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/testing">
              <i className="icon-test mobile-only"></i>
              <span>{translate("TESTING.GENERAL.TEST", intl)}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/rewards">
              <i className="icon-rewards mobile-only"></i>
              <span>{translate("REWARDS.GENERAL.REWARDS", intl)}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/support">
              <i className="icon-chat mobile-only"></i>
              <span>{translate("SUPPORT.GENERAL.SUPPORT", intl)}</span>
            </NavLink>
          </li>
          <li className="mobile-only">
            <NavLink to="/profile">
              <i className="icon-profile mobile-only"></i>
              <span>{translate("PROFILE.GENERAL.PROFILE", intl)}</span>
            </NavLink>
          </li>
          <li className="desktop-only">
            <NavLink to="/profile">
              <span className="profile">
                {user?.first_name?.[0]?.toUpperCase()}
              </span>
            </NavLink>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default injectIntl(Navbar);
