import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { translate } from "../../../../../_artifact/i18n";
import { fetchPreviousTestDetails, clear } from "../../_redux/previousTests";

function TestDetail({ intl, history }) {
  const dispatch = useDispatch();
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const { pets, previousTests } = state;
  const { selectedPreviousTest, testDetails } = previousTests;
  const { selectedPet } = pets;

  const [questionResponse, setQuestionResponse] = useState(null);
  useEffect(() => {
    setQuestionResponse(
      testDetails?.question_response?.length < 4
        ? testDetails?.question_response
        : testDetails?.question_response?.filter(({ question_id }) =>
            question_id.includes(intl.locale)
          )
    );
  }, [testDetails]);

  useEffect(() => {
    if (selectedPreviousTest?.test_schedule_id) {
      dispatch(
        fetchPreviousTestDetails({
          panelist_id: selectedPet.panelist_id,
          test_schedule_id: selectedPreviousTest?.test_schedule_id,
          product_id: selectedPreviousTest?.product_id,
        })
      );
    } else {
      history.push("/testing/pets");
    }
    return () => dispatch(clear());
  }, []);

  return (
    <div className="inner-page">
      <div className="test-info-wrap">
        <Link to={"/testing/pets"} className="back-btn">
          <img src="../assets/images/left-arrow.svg" alt="" />
        </Link>
        <div className="test-info">
          <h4>{selectedPreviousTest?.product_id}</h4>
          <p>
            {selectedPreviousTest?.test_name} <span className="dot"></span>{" "}
            {selectedPreviousTest?.start_date}
          </p>
        </div>
      </div>
      <div className="test-box">
        <h3 className="test-box-title">
          {translate("TESTING.GENERAL.FEEDBACK_FROM_SPECIALS", intl)}:{" "}
        </h3>
        <div className="specialist-comments">
          {testDetails?.specialist_comments?.map(
            ({ specialist_name, comment_timestamp, comment }, index) => (
              <div key={index} className="comments-list">
                <div className="specialist-info">
                  <span className="specialist-name">{specialist_name}</span>
                  <span className="feedback-time">{comment_timestamp}</span>
                </div>
                <p>{comment}</p>
              </div>
            )
          )}
        </div>
        <div className="finish-box">
          <div className="captured-weight">
            <div className="response-text">
              <h4>{translate("TESTING.HEADER.PRE_WEIGHT", intl)}</h4>
              <p>{testDetails?.product_pre_wt} gms</p>
            </div>
            <div className="c-image">
              <img src={testDetails?.product_pre_img} alt="" />
            </div>
          </div>
        </div>

        <div className="finish-box">
          <div className="response-text">
            <h4>{translate("TESTING.HEADER.OBSERVATION", intl)}</h4>
            {questionResponse?.map((answer, index) => (
              <p key={index}>
                {index + 1}. {answer.title}: <strong>{answer.response}</strong>
              </p>
            ))}
          </div>
        </div>

        <div className="finish-box">
          <div className="captured-weight">
            <div className="response-text">
              <h4>{translate("TESTING.HEADER.POST_WEIGHT", intl)}</h4>
              <p>{testDetails?.product_post_wt} gms</p>
            </div>
            <div className="c-image">
              <img src={testDetails?.product_post_img} alt="" />
            </div>
          </div>
        </div>

        {testDetails?.add_comments && (
          <div className="finish-box">
            <div className="response-text">
              <h4>{translate("TESTING.GENERAL.COMMENTS", intl)}</h4>
              <p>{testDetails?.add_comments}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default injectIntl(TestDetail);
