const compareLoginTime = (timeGap = 120) => {
  const date1Str = localStorage.getItem("loginTime");
  const time1 = new Date(date1Str);
  const time2 = new Date();
  if (time1) {
    const timeDifference = time2?.getTime() - time1?.getTime();
    const twoHourGap = timeGap * 60 * 1000; // 2 hours in milliseconds
    if (timeDifference < twoHourGap) {
      console.log("The second time is less than 2 hours after the first time.");
      return true;
    } else {
      localStorage.clear();
    }
  }
  return false;
};

const resetSelectedTrial = () => {
  localStorage.removeItem("selectedTest");
  localStorage.removeItem("question");
  localStorage.removeItem("answer");
  localStorage.removeItem("preWeight");
  localStorage.removeItem("anwserObj");
  localStorage.removeItem("startDate");
  localStorage.removeItem("end_time");
};

export { compareLoginTime, resetSelectedTrial };
