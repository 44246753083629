import React, { useState } from "react";

function ConsentES(props) {
  const [showPII, setShowPII] = useState(false);
  const [showResearch, setShowResearch] = useState(false);
  return (
    <>
      {showPII && (
        <>
          <h3>AVISO DE PRIVACIDAD</h3>
          <div className="modal-content-body text-left">
            <p>
              Este aviso de privacidad le brinda información sobre cómo
              recopilamos, usamos y compartimos Datos Personales. Si tiene
              alguna pregunta general, desea ponerse en contacto con el Equipo
              de Privacidad, el responsable de protección de datos o desea
              ejercer sus derechos en virtud de la legislación aplicable, favor
              de contactar a{" "}
              <a href="mailto:privacy@effem.com">privacy@effem.com</a>
            </p>

            <p>
              Esta declaración de privacidad se aplica a los Datos Personales
              recopilados o procesados por la aplicación <i>PetVoicePro</i>. El
              aviso de privacidad se aplica en caso de recopilar datos en
              persona, a través de nuestros sitios web, aplicaciones móviles y /
              o correo electrónico. Sus datos personales incluyen información o
              una combinación de información que podría usarse directa o
              indirectamente para identificarlo ("Datos Personales"). Esto
              incluye identificadores como un nombre, número de identificación,
              datos de ubicación o geolocalización, identificador en línea o
              factores sobre la identidad física, fisiológica, genética, mental,
              económica, cultural o social. Tenga en cuenta que la definición de
              Datos Personales puede variar según la región o el País. Si
              recopilamos información que se considera Datos Personales en su
              región, la trataremos como tal de acuerdo con esta declaración o
              aviso de privacidad y la ley local aplicable.
            </p>

            <p>Recopilamos los siguientes Datos Personales específicos:</p>

            <p>
              <strong>Datos personales de contacto.</strong> Esto incluye
              información como nombres, domicilios, números de teléfono o
              direcciones de correo electrónico. Por ejemplo, cuando proporciona
              detalles de contacto durante el registro en línea, cuando hace
              negocios con nosotros o cuando nos brinda servicios. Algunos
              sitios web le permiten crear un nombre de usuario y una contraseña
              para configurar una cuenta.
            </p>

            <p>
              <strong>Información demográfica.</strong> Por ejemplo, datos como
              edad, sexo, afición o actividades, gustos o preferencias.
            </p>

            <p>
              <strong>Información recopilada de la aplicación web.</strong> Esto
              incluye la información que se recopila cuando interactúa con
              nosotros. Por ejemplo, comentarios, fotos u otra información que
              publique a través de blogs, tableros de mensajes o redes sociales.
            </p>

            <p>
              <strong>Información del dispositivo.</strong> Por ejemplo, la
              dirección del Protocolo de Internet (IP), el identificador del
              dispositivo, el sistema operativo de su dispositivo, el tipo de
              navegador, los sitios web visitados antes o después de visitar
              nuestro sitio web, las páginas visitadas y las actividades
              realizadas en nuestro sitio web, así como la interacción con los
              anuncios publicitarios.
            </p>

            <p>
              Cualquier otra información que hayamos recopilado de usted con su
              consentimiento.
            </p>

            <p>
              La información que recopilamos sobre su mascota, como especie,
              raza, fecha de nacimiento o historial de tratamiento, no es
              considerada como Datos Personales.
            </p>

            <p>
              Podemos usar sus Datos Personales para varios fines legítimos, que
              incluyen:
            </p>

            <p>
              Para comunicarnos con usted y brindarle información. Podemos
              utilizar sus datos personales para comunicarnos con usted, para
              responder a sus consultas o comentarios, o para brindarle
              actualizaciones y noticias. También podemos combinar Datos
              Personales para realizar investigaciones internas de productos.
              Esto nos permite comprender dónde se encuentran los clientes en
              nuestras diferentes marcas y negocios y nos ayudará a mejorar
              nuestros productos y servicios.
            </p>

            <p>
              Los respaldos legales que nos permiten procesar sus Datos
              Personales son para la investigación científica y otros fines
              comerciales legítimos. También podemos basarnos en nuestro interés
              legítimo para mejorar las prácticas comerciales y de mercadeo.
              Esto incluye, entre otros: cumplimiento legal, investigación y
              análisis, combinación de datos, estudios clínicos, evaluación de
              diagnóstico, tratamiento de mascotas y otros fines descritos
              anteriormente. Cuando nos basemos en nuestro interés legítimo,
              llevaremos a cabo una evaluación de dicho interés para asegurarnos
              de que consideramos y equilibramos cualquier impacto potencial en
              sus derechos según las leyes de Protección de Datos aplicables
              antes de llevar a cabo cualquier procesamiento de datos. No
              utilizaremos sus Datos Personales para actividades en las que
              nuestros intereses sean inferiores al impacto que sobre usted
              podría casuar su uso, a menos que tengamos su consentimiento o la
              ley lo requiera o permita.
            </p>

            <p>
              Podemos compartir o divulgar sus Datos Personales para fines que
              sean consistentes con este Aviso de Privacidad en los siguientes
              casos:
            </p>

            <p>
              <strong>
                Dentro de nuestra compañía y nuestras empresas filiales.
              </strong>{" "}
              Por ejemplo, para combinar los Datos Personales recopilados sobre
              usted.
            </p>

            <p>
              <strong>Con proveedores o agentes.</strong> Podemos compartir sus
              datos personales con empresas que hemos contratado para que nos
              presten servicios. Si compartimos datos personales con empresas
              que nos brindan servicios, no se les permite usarlos para ningún
              otro propósito. Deben mantener la confidencialidad de sus Datos
              Personales, a menos que usted otorgue su consentimiento.
            </p>

            <p>
              Para apoyar otros fines comerciales legítimos. Esto incluye, entre
              otros, el cumplimiento legal, la promoción de ensayos clínicos, la
              evaluación del diagnóstico y el tratamiento de mascotas.
            </p>

            <p>
              Podemos combinar información anónima o no identificable con otra
              información anónima o no identificable de otras fuentes. También
              podemos compartir información agregada y anónima con terceros,
              incluidos, por ejemplo: asesores, anunciantes e inversionistas
              para el análisis del negocio.
            </p>

            <p>
              He leído la Declaración de Privacidad. Confirmo mi consentimiento
              para la recopilación de Datos Personales para fines de
              investigación
            </p>
          </div>
          <p style={{ textAlign: "left", marginTop: 10 }}>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowPII(false);
              }}
            >
              Atrás
            </a>
          </p>
        </>
      )}

      {showResearch && (
        <>
          <h3>Acuerdo de investigación para el propietario de mascota</h3>
          <div className="modal-content-body text-left">
            <p>
              La investigación realizada está al servicio de un fabricante de
              alimentos para mascotas no revelado o de identidad reservada. El
              objetivo principal de los estudios es comprender cómo funcionan
              varios productos de alimentos para mascotas. Los detalles clave se
              destacan a continuación.
            </p>
            <h4 style={{ textAlign: "center" }}>
              Participación en la investigación
            </h4>
            <ol style={{ paddingLeft: "20px" }}>
              <li>
                La participación en la investigación es voluntaria y está
                confirmada por el consentimiento escrito a continuación por
                parte del propietario de los animales.
              </li>
              <li>
                La prueba involucra mascotas domésticas (incluso esterilizadas y
                castradas), que se encuentran en buen estado de salud
                (físicamente sanas, no embarazadas y sin dietas específicas).
              </li>
              <li>
                Se determina la fecha de inicio de cada prueba y se informa al
                dueño de la mascota con anticipación. Si por alguna razón no
                puede participar en la prueba, favor de informarlo al
                especialista en metodología.
              </li>
              <li>
                Una descripción detallada de la prueba está incluida en las
                instrucciones que el propietario recibe cada vez con el
                alimento.
              </li>
            </ol>

            <h4 style={{ textAlign: "center" }}>
              Derechos y obligaciones de la compañía
            </h4>
            <ol style={{ paddingLeft: "20px" }}>
              <li>
                La compañía garantiza la seguridad alimentaria de la dieta
                utilizada en la investigación.
              </li>
              <li>
                La empresa se compromete a informar a todos los propietarios de
                cualquier cambio con antelación.
              </li>
              <li>
                La empresa tiene derecho a cambiar la metodología de prueba.
              </li>
              <li>
                La empresa tiene el derecho de cancelar la participación del
                usuario en cualquier momento a su discreción.
              </li>
            </ol>

            <h4 style={{ textAlign: "center" }}>
              Derechos y obligaciones de los propietarios
            </h4>
            <ol style={{ paddingLeft: "20px" }}>
              <li>
                Cada propietario tiene derecho a retirarse de la participación
                en la investigación en cualquier momento, comunicando
                previamente su intención.
              </li>
              <li>
                El propietario está obligado a seguir las instrucciones y
                completar la prueba de manera oportuna y precisa.
              </li>
              <li>
                El propietario garantiza la veracidad de los datos informados
              </li>
              <li>
                El propietario está obligado a comunicar con prontitud a los
                representantes de la compañía cualquier cambio en el estado
                físico del animal (embarazo, lesión, enfermedad, etc.), así como
                la llegada de nuevos animales a la casa.
              </li>
            </ol>

            <p>
              He leído los términos y condiciones. Confirmo mi consentimiento
              para participar en esta investigación.
            </p>
          </div>
          <p style={{ textAlign: "left", marginTop: 10 }}>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowResearch(false);
              }}
            >
              Atrás
            </a>
          </p>
        </>
      )}

      {!showPII && !showResearch && (
        <div className="text-left">
          <p>¡Estimado Usuario!</p>
          <p>
            La{" "}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowPII(true);
              }}
            >
              privacidad de tus datos
            </a>{" "}
            y el{" "}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowResearch(true);
              }}
            >
              consentimiento para participar en los estudios
            </a>{" "}
            de investigación ¡es muy importante para nosotros! Antes de tener
            acceso a la aplicación por favor proporciona tu consentimiento.
          </p>
          <p>
            Estoy de acuerdo en participar en los estudios de investigación
            descritos y de acuerdo en como se recolectarán, usaran y compartirán
            mis datos personales.
          </p>
        </div>
      )}
    </>
  );
}

export default ConsentES;
