export default [
  {
    test_schedule_id: 1,
    test_name: "DDAD-099-M",
    product_id: "Product_ABC",
    start_date: "2021-09-27 00:00:00",
    end_date: "2021-09-28 00:00:00",
    comment: "Amet minim mollit non deserunt ...",
  },
];
