import { createSlice } from "@reduxjs/toolkit";
import dateHelper from "../../../../_artifact/helpers/dateHelper";

const initialState = {
  startDate: null,
};

// Slice
export const timerSlice = createSlice({
  name: "timer",
  initialState: initialState,
  reducers: {
    cleared: () => initialState,
    timerStarted: (state, action) => {
      state.startDate = dateHelper.now();
    },
    timerStopped: (state, action) => {
      state.startDate = null;
    },
  },
});

// Actions
const { actions } = timerSlice;

export const clear = () => (dispatch) => dispatch(actions.cleared());
export const startTimer = () => (dispatch) => dispatch(actions.timerStarted());
export const stopTimer = () => (dispatch) => dispatch(actions.timerStopped());
