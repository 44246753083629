import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "../_artifact/components/Loader";
import config from "./config";
import Refresh from "./modules/Auth/pages/Refresh";
import { fetchPetNotifs } from "./modules/Testing/_redux/pets";

const AppUIContext = createContext();

export function useAppUIContext() {
  return useContext(AppUIContext);
}

export const AppUIConsumer = AppUIContext.Consumer;

export function AppUIProvider({ children }) {
  const [pets, setPets] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [tab, setTab] = useState(config.tabs.upcoming);

  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const { petNotifs } = state?.pets?.entities;
  const curLink = useLocation().pathname;
  const onTestingPage = curLink.includes("testing");
  useEffect(() => {
    setPets(
      petNotifs?.map(({ panelist_id }) => ({ panelist_id, read: false }))
    );
  }, [petNotifs]);

  const dispatch = useDispatch();
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email && !onTestingPage)
      dispatch(fetchPetNotifs({ panelist_email: email }));
  }, [dispatch]);

  const setReadReview = (id) => {
    if (pets?.length === 0 || !pets) return;

    const newPets = [...pets];
    const index = newPets.findIndex(({ panelist_id }) => panelist_id === id);
    if (index === -1) return;
    newPets[index].read = true;
    setPets(newPets);
  };

  const getReadReview = (id) => {
    if (pets?.length === 0 || !pets) return false;

    const pet = pets.find(({ panelist_id }) => panelist_id === id);
    if (!pet) return false;
    return pet.read;
  };

  const value = {
    pets,
    setPets,
    setReadReview,
    getReadReview,
    loading,
    setLoading,
    tab,
    setTab,
    rejected,
    setRejected,
  };

  return (
    <>
      {loading && <Loader />}
      <Refresh />
      <AppUIContext.Provider value={value}>{children}</AppUIContext.Provider>
    </>
  );
}
