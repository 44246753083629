import React, { createContext, useEffect } from "react";
import { useMemo } from "react";
import { useContext } from "react";

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";
const initialState = {
  selectedLang: "en",
};

function getConfig() {
  // const ls = sessionStorage.getItem(I18N_CONFIG_KEY);
  const ls = localStorage.getItem("language");
  if (ls) {
    try {
      return {
        selectedLang: ls,
      };
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export function setLanguage(lang) {
  localStorage.setItem("language", lang);
  window.location.reload();
}

export function getSelectedLanguage() {
  const i18nConfig = localStorage.getItem("language");
  if (!i18nConfig) return null;
  return i18nConfig;
}

const I18nContext = createContext();

export function useLang() {
  return useContext(I18nContext).selectedLang;
}

export function withI18n(Component) {
  class WithI18n extends React.Component {
    static displayName = `WithI18n(${Component.displayName || Component.name})`;

    static contextType = I18nContext;

    render() {
      return <Component {...this.props} menu={this.context} />;
    }
  }

  return WithI18n;
}

export const I18nConsumer = I18nContext.Consumer;

export function ArtifactI18nProvider({ children }) {
  const lang = useMemo(getConfig, []);

  useEffect(() => {
    const checkForUpdate = async () => {
      const registration = await navigator.serviceWorker.getRegistration();

      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
        alert(
          "A new update has been detected. Please select'OK' to proceed with the update"
        );
        window.location.reload();
      }
    };

    navigator.serviceWorker.addEventListener("updatefound", checkForUpdate);

    return () => {
      navigator.serviceWorker.removeEventListener(
        "updatefound",
        checkForUpdate
      );
    };
  }, []);
  return (
    <>
      <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
    </>
  );
}

export function translate(id, intl, values = null) {
  return intl.formatMessage({ id }, values);
}
