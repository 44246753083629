import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, shallowEqual, useSelector } from "react-redux";

import Loader from "../../../../_artifact/components/Loader";
import * as auth from "../_redux/authRedux";
import Refresh from "./Refresh";
import * as logsPanelistsActions from "../../Tracking/_redux/tracking";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Logout(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isSpecialist } = useSelector(
    (state) => ({ isSpecialist: state.auth.user?.isSpecialist }),
    shallowEqual
  );
  useEffect(() => {
    if (isSpecialist) {
      props.logout();
      localStorage.clear();
      window.location.href = "/";
    } else {
      localStorage.clear();
      props.logout();
      dispatch(logsPanelistsActions.clear());
    }
    // history.push("/");
    // window.location.assign("/");
    // window.location.reload();
  }, [dispatch]);

  return (
    <>
      <Refresh />
      <Loader />
    </>
  );
}

export default connect(null, auth.actions)(Logout);
