// TODO: set timer to 20 mins
export default {
  timer: 20,
  // timer: 1,
  innerWidth: 1280,
  morningHours: 5,
  afternoonHours: 12,
  eveningHours: 18,
  tabs: { upcoming: 0, previous: 1 },
  file: { maxSizeMB: 1, maxWidthOrHeight: 1024 },
  errorStatus: { unauthorized: 401, internalServerError: 500 },
};
