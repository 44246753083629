import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useLocation } from "react-router";
import { ProgressBar, Step } from "react-step-progress-bar";
import { translate } from "../../../../_artifact/i18n/Artifacti18n";

function TestingProgressBar({ intl }) {
  const [progress, setProgress] = useState([undefined, 0]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/testing/codes")
      return setProgress([undefined, 0]);
    if (location.pathname === "/testing/cameras/pre")
      return setProgress([0, 25]);
    if (location.pathname === "/testing/timerstart")
      return setProgress([25, 50]);
    if (location.pathname === "/testing/questionnaire")
      return setProgress([25, 50]);
    if (location.pathname === "/testing/cameras/post")
      return setProgress([50, 75]);
    if (location.pathname === "/testing/finish") return setProgress([75, 100]);

    setProgress(null);
  }, [location]);

  if (progress === null) return null;

  const stepClasses = ({ accomplished, position }) => {
    let classes = "step";
    if (accomplished) return `${classes} accomplished`;
    if (progress[1] === position) return `${classes} active`;
    return classes;
  };

  return (
    <div className="progressbar_wrap">
      <ProgressBar percent={progress[0]}>
        <Step>
          {(step) => (
            <div className={stepClasses(step)}>
              <p>{translate("TESTING.HEADER.SCAN", intl)}</p>
            </div>
          )}
        </Step>
        <Step>
          {(step) => (
            <div className={stepClasses(step)}>
              <p>{translate("TESTING.HEADER.PRE_WEIGHT", intl)}</p>
            </div>
          )}
        </Step>
        <Step>
          {(step) => (
            <div className={stepClasses(step)}>
              <p>{translate("TESTING.HEADER.OBSERVATION", intl)}</p>
            </div>
          )}
        </Step>
        <Step>
          {(step) => (
            <div className={stepClasses(step)}>
              <p>{translate("TESTING.HEADER.POST_WEIGHT", intl)}</p>
            </div>
          )}
        </Step>
        <Step>
          {(step) => (
            <div className={stepClasses(step)}>
              <p>{translate("TESTING.HEADER.FINISH", intl)}</p>
            </div>
          )}
        </Step>
      </ProgressBar>
    </div>
  );
}

export default injectIntl(TestingProgressBar);
