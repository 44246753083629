import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { injectIntl } from "react-intl";
import { translate } from "../i18n/Artifacti18n";
function RedirectModel({ intl, isOpen, history }) {
  const [open, setOpen] = useState(isOpen);

  const onClose = () => {
    history.replace("/testing/codes");
    setOpen(false);
  };
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      centered
      footer={null}
      visible={open}
      closable={false}
      className="borderRadius"
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1rem",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <h2 className="modal-title">{translate("TESTING.REDIRECT.MSG", intl)}</h2>
      <i className={"icon-circle-exclamation text-danger font-200"}></i>
      <button className="btn btn-primary btn-block" onClick={onClose}>
        {translate("TESTING.BUTTON.RESTART", intl)}
      </button>
    </Modal>
  );
}

export default injectIntl(RedirectModel);
