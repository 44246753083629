import React from "react";

function Loader() {
  return (
    <>
      <div className="loader" translate="no"></div>
      <div className="loader-bg" translate="no"></div>
    </>
  );
}

export default Loader;
