import React from "react";
import { Dropdown, Layout, Menu, Modal, Col, Row } from "antd";
import marsLogo from "../../../../assests/images/marslogo.svg";
import bellIcon from "../../../../assests/images/bell-icon.svg";
import notification from "../../../../assests/images/notification.svg";
import { Avatar, Button } from "antd";
import "../../../../assests/styles/style.scss";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  catchOverviewError,
  fetchTestNotifs,
  updateClearNotifs,
  updateMarkNotifRead,
  updateMarkNotifsRead,
} from "../../../../app/modules/Methodologist/_redux/overview";
import jsonHelper from "../../../helpers/jsonHelper";
import Loader from "../loader";
import { testClick } from "../../../../app/modules/Methodologist/_redux/results";
import { NotifsDaysTitles } from "../../../../app/modules/Methodologist/pages/MethodologistUIHelper";

const { header } = Layout;

export default function MethodologistNavBar({ history }) {
  const [badge, setBadge] = useState(0);
  const [testNotifs, setTestNotifs] = useState([]);
  const [active, setActive] = useState(false);
  const curLink = useLocation().pathname;
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const { loader } = state.overviewData;
  const { testNotifs: testNotifsInStore } = state.overviewData;
  const { email_id: email, first_name } = state.auth.user;

  const getTestNotifs = () => dispatch(fetchTestNotifs({ email }));
  const handleReadAll = () => {
    dispatch(updateMarkNotifsRead({ email }))
      .then(() => getTestNotifs())
      .catch((error) => {
        error.clientMessage = "Can't mark all notifs as read";
        dispatch(catchOverviewError(error));
      });
  };

  const handleRead = (test_id) => {
    dispatch(updateMarkNotifRead({ email, test_id }))
      .then(() => {
        getTestNotifs();
        dispatch(testClick({ testCode: test_id }));
        history.push("/methodologist/testresultsdetailed");
        setActive(false);
      })
      .catch((error) => {
        error.clientMessage = "Can't mark a notif as read";
        dispatch(catchOverviewError(error));
      });
  };

  const handleClearAll = () => {
    dispatch(updateClearNotifs({ email }))
      .then(() => getTestNotifs())
      .catch((error) => {
        error.clientMessage = "Can't clear all notifs";
        dispatch(catchOverviewError(error));
      });
  };

  const getDay = (date) => {
    const now = new Date();
    const datetime = new Date(date);

    // Today
    if (
      now.getFullYear() === datetime.getFullYear() &&
      now.getMonth() === datetime.getMonth() &&
      now.getDate() === datetime.getDate()
    )
      return NotifsDaysTitles.today;

    // Yesterday
    if (
      now.getFullYear() === datetime.getFullYear() &&
      now.getMonth() === datetime.getMonth() &&
      now.getDate() !== datetime.getDate()
    ) {
      if (now.getDate() - datetime.getDate() === 1)
        return NotifsDaysTitles.yesterday;
      return NotifsDaysTitles.older;
    }

    // Older
    return NotifsDaysTitles.older;
  };

  const getDate = (date) => {
    const now = new Date();
    const datetime = new Date(date);

    if (
      now.getFullYear() === datetime.getFullYear() &&
      now.getMonth() === datetime.getMonth() &&
      now.getDate() === datetime.getDate()
    ) {
      if (now.getHours() === datetime.getHours()) {
        const timeSpan = (now - datetime) / 1000 / 60;
        return parseInt(timeSpan) + " min ago";
      }

      const timeSpan = (now - datetime) / 1000 / 60 / 24;
      return parseInt(timeSpan) + " hr ago";
    }

    const timeSpan = (now - datetime) / 1000 / 60 / 60 / 24;
    return parseInt(timeSpan) + " day ago";
  };

  useEffect(() => {
    const notifs = JSON.parse(JSON.stringify(testNotifsInStore));

    notifs.forEach((notif) => {
      notif.day = getDay(notif.creation_date);
      notif.date = getDate(notif.creation_date);
    });

    const notifGroups = {
      [NotifsDaysTitles.today]: notifs.filter(
        ({ day }) => day === NotifsDaysTitles.today
      ),
      [NotifsDaysTitles.yesterday]: notifs.filter(
        ({ day }) => day === NotifsDaysTitles.yesterday
      ),
      [NotifsDaysTitles.older]: notifs.filter(
        ({ day }) => day === NotifsDaysTitles.older
      ),
    };

    setTestNotifs(
      Object.keys(notifGroups).map((day) => ({
        day,
        notifs: notifGroups[day],
      }))
    );

    // console.log(notifsProcessed);
  }, [testNotifsInStore]);

  useEffect(
    () =>
      setBadge(testNotifsInStore.filter(({ notified }) => !notified).length),
    [testNotifsInStore]
  );

  const dispatch = useDispatch();
  useEffect(() => getTestNotifs(), [dispatch]);

  const AccountMenu = () => (
    <Menu>
      <Menu.Item
        onClick={(e) => {
          // sessionStorage.clear();
          history.push("/methodologist/logout");
          // history.replace("/methodologist");
          // window.location.reload();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <header className="specialist-header">
      <div className="header-inner">
        <div className="logo desktop-only">
          <a
            href="#"
            onClick={() => {
              history.replace("/methodologist/home");
            }}
          >
            <img src={marsLogo} alt="logo" />
          </a>
        </div>
        <ul className="nav">
          <li>
            <a
              href="#"
              className={
                curLink === "/methodologist/testresults" ? "active" : ""
              }
              onClick={() => {
                history.replace("/methodologist/testresults");
              }}
            >
              <span>Test Results</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              className={
                curLink === "/methodologist/testmanagement" ? "active" : ""
              }
              onClick={() => {
                history.replace("/methodologist/testmanagement");
              }}
            >
              <span>Test Management</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              className={curLink === "/methodologist/panelists" ? "active" : ""}
              onClick={() => {
                history.replace("/methodologist/panelists");
              }}
            >
              <span>Panelists</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              className={curLink === "/methodologist/queries" ? "active" : ""}
              onClick={() => {
                history.replace("/methodologist/queries");
              }}
            >
              <span>Queries</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              className={curLink === "/methodologist/manage" ? "active" : ""}
              onClick={() => {
                history.replace("/methodologist/manage");
              }}
            >
              <span>Manage</span>
            </a>
          </li>
          <li
            className={`${active ? "active" : ""}`}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
          >
            <a href="#">
              <img src={bellIcon} alt="logo" />
              {badge > 0 && <span className="badge">{badge}</span>}
            </a>
            <div className="notification-wrap">
              <Loader loading={loader}>
                <div className="notification-heading">
                  <h4>Notifications</h4>
                  <div>
                    <a href="#" onClick={handleReadAll}>
                      Mark all as read
                    </a>
                    {/* <a href="#" onClick={handleClearAll}>
                      Clear All
                    </a> */}
                  </div>
                </div>
                <div className="notification-body">
                  {testNotifs.map(({ day, notifs }, indexGroup) => (
                    <div key={indexGroup}>
                      {notifs.length > 0 && (
                        <div className="n-loop">
                          <div className="n-day">{day}</div>
                          <ul className="notification-list">
                            {notifs.map((notif, indexNotif) => (
                              <li
                                key={indexNotif}
                                className={notif.notified ? "" : "active"}
                                onClick={() => handleRead(notif.test_id)}
                              >
                                <div className="n-image">
                                  <img src={notification} alt="" />
                                </div>
                                <div className="n-content">
                                  <h5>
                                    {notif.test_id}: Test Completion Successful!
                                  </h5>
                                  <p>{notif.missed_tests} missed test</p>
                                  <span>{notif.date}</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {testNotifs.length > indexGroup + 1 &&
                            testNotifs[indexGroup + 1] &&
                            testNotifs[indexGroup + 1].notifs.length > 0 && (
                              <hr />
                            )}
                        </div>
                      )}
                    </div>
                  ))}

                  {/* <div className="n-loop">
                  <div className="n-day">Yesterday</div>
                  <ul className="notification-list">
                    <li>
                      <div className="n-image">
                        <img src={notification} alt="" />
                      </div>
                      <div className="n-content">
                        <h5>DDAD-087-M: Test Completion Successful!</h5>
                        <p>10 missed test</p>
                        <span>2 min ago</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="n-loop">
                  <div className="n-day">Yesterday</div>
                  <ul className="notification-list">
                    <li>
                      <div className="n-image">
                        <img src={notification} alt="" />
                      </div>
                      <div className="n-content">
                        <h5>DDAD-087-M: Test Completion Successful!</h5>
                        <p>10 missed test</p>
                        <span>2 min ago</span>
                      </div>
                    </li>
                    <li>
                      <div className="n-image">
                        <img src={notification} alt="" />
                      </div>
                      <div className="n-content">
                        <h5>DDAD-087-M: Test Completion Successful!</h5>
                        <p>10 missed test</p>
                        <span>2 min ago</span>
                      </div>
                    </li>
                    <li>
                      <div className="n-image">
                        <img src={notification} alt="" />
                      </div>
                      <div className="n-content">
                        <h5>DDAD-087-M: Test Completion Successful!</h5>
                        <p>10 missed test</p>
                        <span>2 min ago</span>
                      </div>
                    </li>
                  </ul>
                </div> */}
                </div>
              </Loader>
            </div>
          </li>
          <li>
            <Dropdown overlay={AccountMenu}>
              <span className="profile">{first_name[0].toUpperCase()}</span>
            </Dropdown>
          </li>
        </ul>
      </div>
    </header>
  );
}
