import React, { Suspense, lazy } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "../_artifact/components/Loader";
import { AppUIProvider } from "./AppUIContext";
import Home from "./modules/Home/pages/Home";
import TestingPage from "./modules/Testing/pages/TestingPage";
// const HomePage = lazy(() => import("./modules/Home/pages/HomePage"));
// const TestingPage = lazy(() => import("./modules/Testing/pages/TestingPage"));
const SupportPage = lazy(() => import("./modules/Support/pages/SupportPage"));
const ProfilePage = lazy(() => import("./modules/Profile/pages/ProfilePage"));
const RewardsPage = lazy(() => import("./modules/Rewards/pages/RewardsPage"));
const MethodologistPage = lazy(() =>
  import("./modules/Methodologist/pages/MethodologistPage")
);

function BasePage() {
  return (
    <AppUIProvider>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/testing" component={TestingPage} />
          <Route path="/support" component={SupportPage} />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/rewards" component={RewardsPage} />
          <Route path="/methodologist" component={MethodologistPage} />
        </Switch>
      </Suspense>
    </AppUIProvider>
  );
}

export default BasePage;
