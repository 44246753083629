import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: "" });

const userName = localStorage.getItem("email");
const reactPlugin = new ReactPlugin();

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,

    extensions: [reactPlugin],

    enableAutoRouteTracking: true,

    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

ai.addTelemetryInitializer((t) => {
  // Update criteria as per your need.
  if (t.ext.trace.name === "/admin")
    // or anything else
    return false; // disable
  return true; // enable everything else
});

ai.loadAppInsights();

ai.addTelemetryInitializer((envelope) => {
  let user = "";
  if (userName) {
    // console.log("userNameuserName", userName)

    user = userName.toLowerCase();
  }

  envelope.tags = envelope.tags || [];

  envelope.tags.push({ "ai.user.accountId": user });
});

export default (Component) => withAITracking(reactPlugin, Component);

export const appInsights = ai.appInsights;
