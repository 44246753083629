import React from "react";

function TimesUp({ text }) {
  const FULL_DASH_ARRAY = 283;
  const RESET_DASH_ARRAY = `-57 ${FULL_DASH_ARRAY}`;

  return (
    <>
      <div className="base-timer-wrap" style={{ margin: "0 0 1rem" }}>
        <div className="base-timer">
          <svg
            className="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g className="base-timer__circle">
              <circle
                className="base-timer__path-elapsed"
                cx="50"
                cy="50"
                r="45"
              ></circle>
              <path
                id="base-timer-path-remaining"
                strokeDasharray={RESET_DASH_ARRAY}
                className="base-timer__path-remaining arc"
                d="
                        M 50, 50
                        m -45, 0
                        a 45,45 0 1,0 90,0
                        a 45,45 0 1,0 -90,0
                        "
              ></path>
            </g>
          </svg>
          <span id="base-timer-label" className="base-timer__label_text">
            {text}
          </span>
        </div>
      </div>
    </>
  );
}

export default TimesUp;
