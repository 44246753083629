import React from "react";
import { injectIntl } from "react-intl";
import Timer from "./Timer";
import config from "../../../../config";
import { translate } from "../../../../../_artifact/i18n";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {trackingLog} from "../../../Tracking/_redux/tracking";

function TimerStart({ intl, history }) {
  const dispatch = useDispatch();
  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );

  const handleClick = () => {
    // Call dispatch
    dispatch(
      trackingLog({
        ...trackingPayload,
        started_timer: 1,
      })
    );

    // Call history.replace
    history.replace("/testing/questionnaire")
  };
  return (
    <div className="inner-page">
      <div className="test-box">
        <h3 className="test-box-title desktop-only">
          <i className="icon-eye" style={{ fontSize: "36px" }}></i>{" "}
          {translate("TESTING.GENERAL.OBSERVE_YOUR_PET", intl)}
        </h3>
        <Timer timerDuration={config.timer} />
        <div className="timer-instruction text-center">
          <p>
            {translate("TESTING.GENERAL.YOU_HAVE", intl)}{" "}
            <strong>
              {config.timer} {translate("TESTING.GENERAL.MINUTES", intl)}
            </strong>{" "}
            {translate("TESTING.GENERAL.TO_FINISH_THE_TEST", intl)}
          </p>
          <p>{translate("TESTING.GENERAL.OBSERVE_YOUR_PET", intl)}</p>
        </div>
        <div className="text-center action-btn">
          <button
            className="btn btn-primary btn-block"
            onClick={handleClick}
          >
            {translate("TESTING.BUTTON.START_THE_TIMER", intl)}
          </button>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(TimerStart);
