import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Modal from "../../../../../_artifact/components/Modal";
import { translate } from "../../../../../_artifact/i18n/Artifacti18n";

import { trackingLog } from "../../../Tracking/_redux/tracking";
import { compareLoginTime } from "../../../../utils";
import { actions } from "../../../Auth/_redux/authRedux";

function ScanCode({ intl, history }) {
  const dispatch = useDispatch();
  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );

  const [productId, setProductId] = useState(null);
  const [isDangerOpen, setIsDangerOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [logOut, setLogOut] = useState(false);

  const handleVerify = () => {
    var verfiyTime = compareLoginTime(90);
    if (verfiyTime) {
      const jsonString = localStorage.getItem("selectedTest");
      const selectedTest = JSON.parse(jsonString);
      const success = selectedTest?.product_id === productId;
      if (success) {
        dispatch(
          trackingLog({
            ...trackingPayload,
            // pet_name: selectedPet.pet_name,
            pet_name: selectedTest.pet_name,
            test_schedule_id: selectedTest.test_schedule_id,
            product_id_page: 1,
            product_id: productId,
            preweight_page: 1,
          })
        );
      }
      setIsDangerOpen(!success);
      setIsSuccessOpen(success);
    } else {
      setLogOut(true);
    }
  };

  return (
    <div className="inner-page">
      <Modal
        isOpen={logOut}
        action={translate("TESTING.SESSION.LOGOUT", intl)}
        title={translate("TESTING.SESSION.EXPIRE", intl)}
        icon="icon-circle-cross text-info font-200"
        onClose={() => dispatch(actions.logout())}
      />
      <Modal
        isOpen={isDangerOpen}
        action={translate("TESTING.BUTTON.ENTER_AGAIN", intl)}
        title={translate("TESTING.GENERAL.VALIDATION_FAILED", intl)}
        icon="icon-circle-cross text-danger font-200"
        onClose={() => setIsDangerOpen(false)}
      />
      <Modal
        isOpen={isSuccessOpen}
        action={translate("TESTING.BUTTON.NEXT", intl)}
        title={translate("TESTING.GENERAL.VALIDATION_SUCCESS", intl)}
        icon="icon-circle-check text-success font-200"
        onClose={() => setIsSuccessOpen(false)}
        onSubmit={() => history.replace("/testing/cameras/pre")}
      />
      <div className="test-box">
        <h3 className="test-box-title desktop-only">
          {" "}
          {translate("TESTING.INPUT.ENTER_PRODUCT_CODE", intl)}
        </h3>
        <div className="enter-code">
          <div className="form-group">
            <label className="mobile-only">
              {translate("TESTING.INPUT.ENTER_PRODUCT_CODE", intl)}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={translate("TESTING.INPUT.ENTER_CODE", intl)}
              onChange={(e) => setProductId(e.target.value)}
            />
          </div>
          <div className="input-instruction">
            {translate("TESTING.GENERAL.CODE_FOUND", intl)}
          </div>
          <div className="text-center action-btn">
            <button
              className="btn btn-primary btn-block"
              onClick={handleVerify}
              disabled={!productId}
            >
              {translate("TESTING.BUTTON.NEXT", intl)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(ScanCode);
