import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import dateHelper from "../../../../_artifact/helpers/dateHelper";
import jsonHelper from "../../../../_artifact/helpers/jsonHelper";

const initialOverviewState = {
  loader: false,
  specialistInfo: {},
  testNotifs: [],
  panelistLogin: {},
};

// Slice
export const overviewSlice = createSlice({
  name: "overviewData",
  initialState: initialOverviewState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loader = false;
      toast.error(action.payload.error.clientMessage);
    },
    startOverviewCall: (state, action) => {
      state.error = null;
      state.loader = true;
      state.panelistLogin = {};
    },
    // findResults
    specialistInfo: (state, action) => {
      const { specialist } = action.payload;
      state.loader = false;
      state.error = null;
      state.specialistInfo = specialist;
    },
    testNotifsFetched: (state, action) => {
      const { entities } = action.payload;
      state.loader = false;
      state.error = null;
      state.testNotifs = entities;
    },
    testNotifsUpdated: (state, action) => {
      state.loader = false;
      state.error = null;
    },
    panelistLoginCreated: (state, action) => {
      state.loader = false;
      state.error = null;
      state.panelistLogin = action.payload.data;
    },
  },
});

// Actions
const { actions } = overviewSlice;

export const catchOverviewError = (error) => (dispatch) =>
  dispatch(actions.catchError({ error }));

export const createPanelist = (body) => (dispatch) => {
  dispatch(actions.startOverviewCall());
  return postLoginSignup(body)
    .then(({ data }) => {
      if (data.email) toast.success(data.message);
      else toast.error(data.message);
      dispatch(actions.panelistLoginCreated({ data }));
    })
    .catch((error) => {
      if (error.response) error.clientMessage = error.response.data.message;
      else error.clientMessage = "Can't create panelist login";
      dispatch(actions.catchError({ error }));
    });
};

export const fetchSpecialistInfo = (body) => (dispatch) => {
  dispatch(actions.startOverviewCall());
  return specialistInfoAPI(body)
    .then((response) => {
      const specialist = response.data;
      dispatch(actions.specialistInfo({ specialist }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find results";
      dispatch(actions.catchError({ error }));
    });
};

export const fetchTestNotifs = (body) => (dispatch) => {
  dispatch(actions.startOverviewCall());
  return getTestNotifs(body)
    .then((response) => {
      dispatch(actions.testNotifsFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch test notifs";
      dispatch(actions.catchError({ error }));
    });
};

export const updateMarkNotifsRead = (body) => (dispatch) => {
  dispatch(actions.startOverviewCall());
  return postMarkNotifsRead(body).then(() => {
    dispatch(actions.testNotifsUpdated());
  });
};

export const updateMarkNotifRead = (body) => (dispatch) => {
  dispatch(actions.startOverviewCall());
  return postMarkNotifRead(body).then(() => {
    dispatch(actions.testNotifsUpdated());
  });
};

export const updateClearNotifs = (body) => (dispatch) => {
  dispatch(actions.startOverviewCall());
  return postClearNotifs(body).then(() => {
    dispatch(actions.testNotifsUpdated());
  });
};

// Crud
export const RESULTS_URL = "specialist";

function postLoginSignup(body) {
  return axios.post("login/signup/", body);
}

function specialistInfoAPI(body) {
  return axios.post(`${RESULTS_URL}/specialistInfo/`, body);
}

function getTestNotifs(body) {
  return axios.post(`${RESULTS_URL}/testNotif/`, body);
}

function postMarkNotifsRead(body) {
  return axios.post(`${RESULTS_URL}/markNotifsRead/`, body);
}

function postMarkNotifRead(body) {
  return axios.post(`${RESULTS_URL}/markNotifRead/`, body);
}

function postClearNotifs(body) {
  return axios.post(`${RESULTS_URL}/clearNotifs/`, body);
}
