import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dateHelper from "../../../../_artifact/helpers/dateHelper";
import jsonHelper from "../../../../_artifact/helpers/jsonHelper";
import { toast } from "react-toastify";
import { Translate } from "../../../../_artifact/i18n/Translate";

const initialState = {
  loading: false,
  totalCount: 0,
  entities: {
    faqs: null,
  },
  lastFetch: null,
  lastError: null,
};

// Slice
export const supportsSlice = createSlice({
  name: "supports",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
      toast.error(action.payload.error.clientMessage);
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    cleared: () => initialState,
    faqsFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.faqs = entities;
      state.lastFetch = dateHelper.now();
    },
  },
});

// Actions
const { actions } = supportsSlice;

export const clear = () => (dispatch) => dispatch(actions.cleared());

export const fetchFaqs = () => (dispatch) => {
  dispatch(actions.startCall());
  return findFaqs()
    .then((response) => {
      dispatch(actions.faqsFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate("SUPPORT.TOAST.FAQ_FETCH_FAILED");
      dispatch(actions.catchError({ error }));
    });
};

// Crud
export const SUPPORTS_URL = "panelist";

function findFaqs() {
  return axios.get(`${SUPPORTS_URL}/getFAQs/`);
}
