import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";

import Modal from "../../../../../_artifact/components/Modal";
import {
  catchUIError,
  saveResponseToServer,
  startTest,
  submitAnswers,
  updateQuestionnaires,
} from "../../_redux/pets";
import Timer from "../timer/Timer";
import config from "../../../../config";
import { translate } from "../../../../../_artifact/i18n";
import jsonHelper from "../../../../../_artifact/helpers/jsonHelper";
import { trackingLog } from "../../../Tracking/_redux/tracking";
import TimesUp from "../timer/TimesUp";

const questionTypeClasses = {
  radio: "first",
  scale: "space-between",
};

const questionTypeTitles = {
  radio: "radio",
  scale: "scale",
};

function Questionnaire({ intl, history }) {
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  // const { selectedPet, selectedTest, testDuration } = state.pets;
  const { testDuration } = state.pets;
  const { questionnaires } = state.pets.entities;

  const dispatch = useDispatch();
  const [timer, setTimer] = useState(config.timer);
  const [isValidatedOpen, setIsValidatedOpen] = useState(false);
  const [isSubmittedOpen, setIsSubmittedOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  // const [questions, setQue] = useState(questionnaires);
  const [que, setQue] = useState(null);
  const [open, setOpen] = useState(false);
  const [showBtn, setShowbtn] = useState(false);
  const isSubmitted = useRef(0);
  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );

  useEffect(() => {
    const getQuens = localStorage.getItem("question");
    const myObject = JSON.parse(getQuens);
    setQue(myObject);

    const jsParse = localStorage.getItem("anwserObj");
    if (jsParse) {
      onSubmit({});
    }
  }, []);

  useEffect(() => {
    const { start_time, end_time } = testDuration;

    // Start the test
    if (!start_time && !end_time) {
      let getStartDate = localStorage.getItem("startDate");
      if (getStartDate) {
        dispatch(startTest(getStartDate));
      } else {
        let now = new Date().toISOString();
        localStorage.setItem("startDate", now);
        dispatch(startTest(now));
      }

      return;
    }
    // Resume the test if time remains
    if (!end_time) {
      const now = new Date();
      const startDate = new Date(start_time);
      const timeSpan = (now - startDate) / 1000 / 60;
      if (timeSpan < timer) {
        setTimer(timer - timeSpan);
      } else timeStopped();
      return;
    }

    // Finish the test
    timeStopped();
  }, [testDuration]);

  const timeStopped = () => {
    setTimer(0);
    setTimeout(() => {
      setShowbtn(true);
      if (!isSubmitted.current) {
        onSubmit({});
      }
    }, 6000);
  };

  const getQuestionClasses = (question_type, valid_answer) => {
    let classes = questionTypeClasses[question_type];
    if (question_type === questionTypeTitles.scale)
      if (valid_answer.range[1] <= 5) classes += " large";
    return classes;
  };

  const handleValidation = (questionnaires) => {
    if (validated) return;

    let repeatCount = 0;
    let repeatValue = "";
    questionnaires.forEach(({ response }, index) => {
      if (!response) return;
      if (index === 0) {
        repeatValue = response;
        return;
      }

      if (repeatValue === response) repeatCount++;
      if (repeatCount === 2) {
        setIsValidatedOpen(true);
        setValidated(true);
        return;
      }

      repeatValue = response;
    });
  };

  const updateQuestions = (answer, index) => {
    const newQuestions = JSON.parse(JSON.stringify(que));
    newQuestions[index].response = answer;
    setQue(newQuestions);
    localStorage.setItem("question", JSON.stringify(newQuestions));
    handleValidation(newQuestions);
    dispatch(updateQuestionnaires(newQuestions));
  };

  const onSubmit = ({ questionnaires, comment }) => {
    if (isSubmitted.current) {
      return;
    }
    isSubmitted.current = 1;
    const jsonString = localStorage.getItem("selectedTest");
    const selectedTest = JSON.parse(jsonString);
    const getQuens = localStorage.getItem("question");
    const myObject = JSON.parse(getQuens);
    const responses = myObject?.map(({ question_id, title, response }) => ({
      question_id,
      title,
      response,
    }));
    dispatch(submitAnswers({ responses, comment }));
    let endTime = localStorage.getItem("end_time");
    let now = new Date().toISOString();
    if (!endTime) {
      localStorage.setItem("end_time", now);
      endTime = now;
    }
    if (new Date(testDuration.start_time) < new Date(endTime)) {
      let question_response = "";

      responses?.forEach(({ question_id, response }) => {
        question_response = {
          ...question_response,
          [question_id]: response === undefined ? null : response,
        };
      });

      const ques_res = question_response && JSON.stringify(question_response);
      if (ques_res) {
        localStorage.setItem("answer", ques_res);
      }
      const jsonParse = localStorage.getItem("anwserObj");
      let val = "";
      if (jsonParse) {
        val = JSON.parse(jsonParse);
      }
      const values = val
        ? val
        : {
            // panelist_id: selectedPet.panelist_id,
            panelist_id: selectedTest.panelist_id,
            test_schedule_id: selectedTest.test_schedule_id,
            product_id: selectedTest.product_id,
            question_response: ques_res,
            add_comments: comment,
            start_time: format(
              new Date(testDuration.start_time),
              "yyyy-MM-dd HH:mm:ss"
            ),
            end_time: format(new Date(endTime), "yyyy-MM-dd HH:mm:ss"),
          };
      if (values) {
        const jsonString = JSON.stringify(values);
        localStorage.setItem("anwserObj", jsonString);
      }
      var retry = 1;
      const api = () => {
        dispatch(saveResponseToServer(jsonHelper.jsonToForm(values)))
          .then(() => {
            dispatch(
              trackingLog({
                ...trackingPayload,
                question_responses: ques_res,
                postweight_page: 1,
              })
            );
            setTimeout(() => {
              setShowbtn(false);
            }, 4000);

            setIsSubmittedOpen(true);
          })
          .catch((error) => {
            dispatch(
              trackingLog({
                ...trackingPayload,
                question_responses: ques_res,
                started_timer: 1,
                api_log:
                  error["stack"] +
                  " | Questionnaire " +
                  format(new Date(), "yyyy-MM-dd HH:mm:ss") +
                  " |" +
                  error.message,
              })
            );
            retry++;
            if (retry < 3) {
              api();
            }
            if (retry === 3) setOpen(true);
            isSubmitted.current = 0;
            error.clientMessage = translate(
              "TESTING.TOAST.TEST_SUBMIT_FAILED",
              intl
            );
            dispatch(catchUIError(error));
          });
      };

      api();
    }
  };

  return (
    <div className="inner-page">
      <Formik
        initialValues={{ questionnaires: que, comment: "" }}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, handleSubmit, values }) => (
          <div className="test-box">
            <h3 className="test-box-title desktop-only">
              <i className="icon-eye" style={{ fontSize: "36px" }}></i>
              {translate("TESTING.GENERAL.OBSERVE_YOUR_PET", intl)}
            </h3>
            <p className="text-center mobile-only" style={{ fontSize: "12px" }}>
              {translate("TESTING.GENERAL.OBSERVE_YOUR_PET", intl)}
            </p>
            <div className="q-instruction-wrap">
              <span className="q-instruction">
                {translate("TESTING.GENERAL.PLEASE_ANSWER_ALL_QUESTIONS", intl)}
              </span>
            </div>
            <div className="questionnaire-timer">
              <Timer
                timerDuration={timer}
                isStarted={true}
                isFullscreen={false}
                onSubmit={handleSubmit}
              />
            </div>

            <div className="question-wrap">
              <dl className="questions-list">
                {que?.map(
                  (
                    {
                      question_id,
                      title,
                      question,
                      question_type,
                      valid_answer,
                      response,
                    },
                    index
                  ) => (
                    <div key={question_id}>
                      <dt>
                        <strong>
                          {index + 1}. {title}:
                        </strong>{" "}
                        {question}
                      </dt>
                      <dd>
                        <ul
                          className={`question-options ${getQuestionClasses(
                            question_type,
                            valid_answer
                          )}`}
                        >
                          {/* Radio type */}
                          {question_type === questionTypeTitles.radio &&
                            valid_answer.map((answer, answerIndex) => (
                              <li key={answerIndex}>
                                <div>
                                  <input
                                    type="radio"
                                    name={question_id}
                                    checked={response === answerIndex + 1}
                                    onChange={() => {
                                      setFieldValue(
                                        `questionnaires[${index}].response`,
                                        answerIndex + 1
                                      );
                                      updateQuestions(answerIndex + 1, index);
                                    }}
                                  />
                                  <label>{answerIndex + 1}.</label>
                                </div>
                                <span>{answer}</span>
                              </li>
                            ))}

                          {/* Scale type */}
                          {question_type === questionTypeTitles.scale &&
                            _.range(
                              valid_answer.range[0],
                              valid_answer.range[1] + 1
                            ).map((answer) => (
                              <li key={answer}>
                                <div>
                                  <input
                                    type="radio"
                                    name={question_id}
                                    checked={response === answer}
                                    onChange={() => {
                                      setFieldValue(
                                        `questionnaires[${index}].response`,
                                        answer
                                      );
                                      updateQuestions(answer, index);
                                    }}
                                  />
                                  <label>{answer}</label>
                                </div>
                              </li>
                            ))}
                        </ul>
                        {question_type === questionTypeTitles.scale && (
                          <div className="answer-suggestion">
                            <span>{valid_answer.least}</span>
                            <span>{valid_answer.greatest}</span>
                          </div>
                        )}
                      </dd>
                    </div>
                  )
                )}
              </dl>
              <div className="form-group">
                <label>
                  {translate("TESTING.GENERAL.COMMENTS_OPTIONAL", intl)}
                </label>
                <textarea
                  placeholder={translate(
                    "TESTING.INPUT.OBSERVATION_COMMENTS",
                    intl
                  )}
                  rows="5"
                  className="form-control"
                  onChange={(e) => setFieldValue("comment", e.target.value)}
                ></textarea>
              </div>
              {showBtn && (
                <button
                  className="btn btn-primary btn-block"
                  onClick={() => {
                    isSubmitted.current = 0;
                    handleSubmit();
                  }}
                >
                  {translate("TESTING.BUTTON.SUBMIT", intl)}
                </button>
              )}
            </div>
          </div>
        )}
      </Formik>
      <Modal
        isOpen={isValidatedOpen}
        action={translate("TESTING.GENERAL.YES", intl)}
        title={translate("TESTING.GENERAL.REPEATING_SAME_VALUE", intl)}
        description={translate("TESTING.GENERAL.ARE_YOU_SURE", intl)}
        icon="icon-circle-exclamation text-danger font-200"
        onClose={() => setIsValidatedOpen(false)}
      />
      <Modal
        isOpen={isSubmittedOpen}
        component={
          <TimesUp text={translate("TESTING.GENERAL.TIMES_UP", intl)} />
        }
        action={translate("TESTING.BUTTON.NEXT", intl)}
        title={translate("TESTING.GENERAL.MOVE_BOWL_AWAY", intl)}
        onClose={() => {
          setIsSubmittedOpen(false);
          history.replace("/testing/cameras/post");
          // window.location.reload();
        }}
      />
      {/* <RedirectModel history={history} isOpen={open} /> */}
    </div>
  );
}

export default injectIntl(Questionnaire);
