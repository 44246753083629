import * as FileSaver from "file-saver";
import ExcelJS from "exceljs/dist/es5/exceljs.browser.js";
import { Space, notification, Empty } from "antd";
import "../../assests/styles/components/header.scss";
import setTestCode from "../../app/modules/Methodologist/pages/tresults/Tresults";

export const statusComponent = (
  className = "",
  catalog = false,
  text,
  record
) => {
  return (
    <Space size="middle">
      {
        <>
          <div
            className={`status-box ${className} ${
              !text ? "" : text.toLowerCase()
            }`}
          >
            {text}
          </div>
        </>
      }
    </Space>
  );
};

const highLightHeader = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "FFFFFFFF" },
  bgColor: { argb: "FF0000FF" },
};

export const testDetailedHeader = [
  {
    title: "LOCAL ID",
    dataIndex: "pet_name",
  },
  {
    title: "SITE PANEL ID",
    dataIndex: "product",
  },
  {
    title: "PLOP ID",
    dataIndex: "product_id",
    key: "product_id",
  },
  {
    title: "PRODUCT DESCRIPTION",
    dataIndex: "product_description",
    key: "pre_weight_image",
    width: 160,
  },
  {
    title: "DATE OF MEAL",
    dataIndex: "test_date",
  },
  {
    title: "MEAL NUM IN DAY",
    dataIndex: "meal_number",
  },
  {
    title: "Offered Food(G)",
    dataIndex: "offered_weight_g",
  },
  {
    title: "Leftover Food(G)",
    dataIndex: "leftover_weight_g",
  },
  {
    title: "INTAKE GRAMS",
    dataIndex: "intake_grams",
  },
  {
    title: "INTAKE PERCENTAGE",
    dataIndex: "intake_%",
  },
];

export async function downloadTestExcel(exportFileName, filename, data, col) {
  const wb = new ExcelJS.Workbook();
  const ws1 = wb.addWorksheet(filename[0]);
  const ws2 = wb.addWorksheet(filename[1]);
  const data1 = data[0];
  const data2 = data[1];
  const col1 = col[0];
  const col2 = col[1];

  // Worksheet 1
  ws1.columns = col1.map((val) => {
    return {
      header: val.titleDescription
        ? val.titleDescription
        : val.title.toUpperCase(),
      key: val.dataIndex,
      width: 20,
      type: ExcelJS.ValueType.String,
    };
  });
  data1
    .map((val) => {
      let keys = val.question_responses
        ? Object.keys(JSON.parse(val.question_responses))
        : [];
      let dataNew = { ...val };
      keys.map(
        (val1) =>
          (dataNew[val1.replaceAll(/\s/g, "")] = JSON.parse(
            val.question_responses
          )[val1])
      );
      return dataNew;
    })
    .forEach((val) => ws1.addRow(val));

  // Worksheet 2
  ws2.columns = col2.map((val) => {
    return {
      header: val.title.toUpperCase(),
      key: val.dataIndex,
      width: 20,
      type: ExcelJS.ValueType.String,
    };
  });
  ws2.addRow(data2);

  ws1.getRow(1).font = { bold: true };
  // ws1.getRow(1).fill = highLightHeader;
  ws1.getRow(1).alignment = { wrapText: true };

  ws2.getRow(1).font = { bold: true };
  // ws2.getRow(1).fill = highLightHeader;

  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(new Blob([buf]), exportFileName + ".xlsx");
}

export async function downloadExcel(filename, data, col) {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet(filename);
  if (filename === "Test Detailed Information") {
    ws.columns = col.map((val) => {
      // if (val.dataIndex === "question_responses") {
      //   return {
      //     header: val.title,
      //     key: val.title.replaceAll(/\s/g, ""),
      //     width: 20,
      //     type: ExcelJS.ValueType.String
      //   };
      // } else {
      return {
        header: val.title.toUpperCase(),
        key: val.dataIndex,
        width: 20,
        type: ExcelJS.ValueType.String,
      };
    });
    data
      .map((val) => {
        let keys = val.question_responses
          ? Object.keys(JSON.parse(val.question_responses))
          : [];
        let dataNew = { ...val };
        keys.map(
          (val1) =>
            (dataNew[val1.replaceAll(/\s/g, "")] = JSON.parse(
              val.question_responses
            )[val1])
        );
        return dataNew;
      })
      .forEach((val) => ws.addRow(val));
  } else if (filename === "Panelist Data") {
    ws.columns = col.map((val) => {
      return {
        header: val.title,
        key: val.dataIndex,
        width: 20,
        type: ExcelJS.ValueType.String,
      };
    });
    data
      .map((val) => {
        let routes_val = val.routes[0] ? val.routes[0] : "";
        return {
          ...val,
          routes: routes_val,
        };
      })
      .forEach((val) => ws.addRow(val));
  } else {
    ws.columns = col.map((val) => {
      return {
        header: val.title,
        key: val.dataIndex,
        width: 20,
        type: ExcelJS.ValueType.String,
      };
    });
    data
      .map((val) => {
        return { ...val };
      })
      .forEach((val) => ws.addRow(val));
  }
  ws.getRow(1).font = { bold: true };
  // ws.getRow(1).fill = highLightHeader;
  const buf = await wb.xlsx.writeBuffer();
  FileSaver.saveAs(
    new Blob([buf]),
    filename + " Export " + new Date().toUTCString() + ".xlsx"
  );
}

export const openNotificationWithIcon = (type, message) => {
  notification[type]({
    message,
    style: {
      width: 400,
      marginRight: (window.innerWidth - 400) / 2,
    },
  });
};

export const columns = [
  {
    title: "Test Code",
    dataIndex: "test_id",
    key: "test_id",
    width: 80,
  },
  {
    title: "Test Name",
    dataIndex: "test_name",
    key: "test_name",
    width: 80,
  },
  {
    title: "Pet",
    dataIndex: "pet",
    key: "pet",
    width: 80,
  },
  {
    title: "Test Format",
    dataIndex: "test_format",
    key: "test_format",
    width: 100,
  },
  {
    title: "Test Start Date",
    dataIndex: "start_date",
    key: "start_date",
    width: 100,
  },
  {
    title: "Methodology",
    dataIndex: "test_type",
    key: "test_type",
    width: 100,
  },
  {
    title: "Status",
    dataIndex: "test_status_type",
    key: "test_status_type",
    width: 140,
  },
];

export const panelist_col = [
  {
    title: "Sign Up Period",
    dataIndex: "signup_period",
    key: "signup_period",
    width: 120,
  },
  {
    title: "Route (North or South)",
    dataIndex: "routes",
    key: "routes",
    width: 170,
  },
  {
    title: "Pet Name",
    dataIndex: "pet_name",
    key: "pet_name",
    width: 120,
  },
  {
    title: "Pet Image",
    dataIndex: "pet_image_url_w_sas",
    key: "pet_image_url_w_sas",
    width: 120,
  },
  {
    title: "Panelist Name",
    dataIndex: "panelist_name",
    key: "panelist_name",
    width: 120,
  },
  {
    title: "Panelist Address",
    dataIndex: "panelist_address",
    key: "panelist_address",
    width: 120,
  },
  {
    title: "Panelist Address Colony",
    dataIndex: "panelist_address_colony",
    key: "panelist_address_colony",
    width: 200,
  },
  {
    title: "Telephone Number",
    dataIndex: "telephone_number",
    key: "telephone_number",
    width: 120,
  },
  {
    title: "Panelist Age Group",
    dataIndex: "panelist_age_group",
    key: "panelist_age_group",
    width: 140,
  },
  {
    title: "Panelist Occupation",
    dataIndex: "panelist_occupation",
    key: "panelist_occupation",
    width: 140,
  },
  {
    title: "Actual Pet Age",
    dataIndex: "actual_pet_age",
    key: "actual_pet_age",
    width: 120,
  },
  {
    title: "Pet Breed",
    dataIndex: "pet_breed",
    key: "pet_breed",
    width: 120,
  },
  {
    title: "Species",
    dataIndex: "species",
    key: "species",
    width: 120,
  },
  {
    title: "Pet DOB",
    dataIndex: "pet_dob",
    key: "pet_dob",
    width: 120,
  },
  {
    title: "Pet Size",
    dataIndex: "pet_size",
    key: "pet_size",
    width: 120,
  },
  {
    title: "Pet Sex",
    dataIndex: "pet_sex",
    key: "pet_sex",
    width: 120,
  },
  {
    title: "Pet Weight",
    dataIndex: "pet_weight",
    key: "pet_weight",
    width: 120,
  },
  {
    title: "Sterilized Status",
    dataIndex: "sterilized_status",
    key: "sterilized_status",
    width: 120,
  },
  {
    title: "Panelist Email ID",
    dataIndex: "panelist_email_id",
    key: "panelist_email_id",
    width: 120,
  },
  // {
  //   title: "Panelist ID",
  //   dataIndex: "panelist_id",
  //   key: "panelist_id",
  //   width: 120,
  // },
];

export const panelist_manage = [
  {
    title: "Panelist Name",
    dataIndex: "panelist_name",
    key: "panelist_name",
    width: 120,
  },
  {
    title: "Pet Name",
    dataIndex: "pet_name",
    key: "pet_name",
    width: 120,
  },
  {
    title: "Pet Image",
    dataIndex: "pet_image_url_w_sas",
    key: "pet_image_url_w_sas",
    width: 120,
  },
  {
    title: "Address",
    dataIndex: "panelist_address",
    key: "panelist_address",
    width: 120,
  },
  {
    title: "Phone Number",
    dataIndex: "telephone_number",
    key: "telephone_number",
    width: 100,
  },
  {
    title: "Route",
    dataIndex: "routes",
    key: "routes",
    width: 75,
  },
  {
    title: "Panel",
    dataIndex: "panel_id",
    key: "panel_id",
    width: 145,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    editable: true,
    width: 80,
  },
];

export const Methodologist_Manage = [
  {
    title: "Name",
    dataIndex: "full_name",
    key: "full_name",
    width: 120,
  },
  {
    title: "Email ID",
    dataIndex: "email_id",
    key: "email_id",
    width: 120,
  },
  {
    title: "Market",
    dataIndex: "country",
    key: "country",
    width: 120,
  },
];

export const results_col = [
  {
    title: "Pet",
    dataIndex: "pet_name",
    key: "pet_name",
    fixed: "left",
    width: 80,
  },
  {
    title: "Pixel Code",
    dataIndex: "product_id",
    key: "product_id",
    fixed: "left",
    width: 100,
  },
  {
    title: "Meal No.",
    dataIndex: "meal_number",
    key: "meal_number",
    fixed: "left",
    width: 100,
  },
  {
    title: "Pre-Weight Image",
    dataIndex: "pre_weight_image",
    key: "pre_weight_image",
    width: 160,
  },
  {
    title: "Offered Food(G)",
    dataIndex: "offered_weight_g",
    key: "offered_weight_g",
    width: 100,
  },
  {
    title: "Post-Weight Image",
    dataIndex: "post_weight_image",
    key: "post_weight_image",
    width: 160,
  },
  {
    title: "Leftover Food(G)",
    dataIndex: "leftover_weight_g",
    key: "leftover_weight_g",
    width: 100,
  },
  {
    title: "Intake abs",
    dataIndex: "intake_weight_g",
    key: "intake_weight_g",
    width: 100,
  },
  {
    title: "Intake %",
    dataIndex: "intake_weight_percent",
    key: "intake_weight_percent",
    width: 100,
  },
  {
    title: "Product Description",
    dataIndex: "prod_descp",
    key: "prod_descp",
    width: 100,
  },
  {
    title: "Panel Id",
    dataIndex: "panel_id",
    key: "panel_id",
    width: 100,
  },
];
export const summary_col = [
  {
    title: "Total Responses Rolled Out",
    dataIndex: "response_rolled_out",
    key: "response_rolled_out",
    fixed: "left",
    width: 80,
  },
  {
    title: "Completion Rate",
    dataIndex: "completion_rate",
    key: "completion_rate",
    fixed: "left",
    width: 100,
  },
  {
    title: "Responses Received",
    dataIndex: "response_received",
    key: "response_received",
    fixed: "left",
    width: 100,
  },
  {
    title: "Approved Responses",
    dataIndex: "approved_responses",
    key: "approved_responses",
    fixed: "left",
    width: 100,
  },
  {
    title: "Rejected Responses",
    dataIndex: "rejected_responses",
    key: "rejected_responses",
    fixed: "left",
    width: 100,
  },
];

export const data = [
  {
    key: "1",
    name: "John Brown",
    chinese: 98,
    math: 60,
    english: 70,
  },
  {
    key: "2",
    name: "Jim Green",
    chinese: 98,
    math: 66,
    english: 89,
  },
  {
    key: "3",
    name: "Joe Black",
    chinese: 98,
    math: 90,
    english: 70,
  },
  {
    key: "4",
    name: "Jim Red",
    chinese: 88,
    math: 99,
    english: 89,
  },
];

export const testResultsDummy = [
  {
    test_id: "20300",
    test_status_type: "Previous Test",
    start_date: "2021-11-19",
    test_name: "MDPD-009-M",
    test_type: "Ranking",
    pet: "Dog",
    test_format: "Dry",
  },
  {
    test_id: "20446",
    test_status_type: "To be reviewed",
    start_date: "2021-09-27",
    test_name: "DDAD-099-M",
    test_type: "Ranking",
    pet: "Dog",
    test_format: "Dry",
  },
  {
    test_id: "205678",
    test_status_type: "Live Test",
    start_date: "2021-11-22",
    test_name: "PQRS-100-M",
    test_type: "Ranking",
    pet: "Dog",
    test_format: "Dry",
  },
];
