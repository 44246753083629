import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ErrorMessage from "../../../../_artifact/components/forms/ErrorMessage";
import * as auth from "../_redux/authRedux";
import Loader from "../../../../_artifact/components/Loader";
import config from "../../../config";

function SSOLogin({ methodologistLogin }) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    enableLoading();
    auth
      .ssoLogin()
      .then(({ data: { access_token: authToken } }) => {
        // disableLoading();
        localStorage.setItem("tok", authToken);
        localStorage.setItem("loginTime", new Date().toISOString());
        methodologistLogin(authToken);
      })
      .catch((error) => {
        disableLoading();
        if (
          error.response &&
          error.response.status === config.errorStatus.internalServerError
        ) {
          window.location = "/api/login/loginpage";
          return;
        }

        if (error.message === "Network Error") setStatus("Login failed");
        else {
          if (error.response) setStatus(JSON.stringify(error.response.data));
          else setStatus("Login failed");
        }
      });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div style={{ margin: 20 }}>
        <ErrorMessage error={status} visible={status} />
        {status && <a onClick={() => window.location.reload()}>Retry</a>}
      </div>
    </>
  );
}

export default connect(null, auth.actions)(SSOLogin);
