import React, { useState } from "react";

function ConsentEN(props) {
  const [showPII, setShowPII] = useState(false);
  const [showResearch, setShowResearch] = useState(false);
  return (
    <>
      {showPII && (
        <>
          <h3>Privacy Statement</h3>
          <div className="modal-content-body text-left">
            <p>
              This Privacy Statement provides you with information as to how we
              collect, use and share Personal Data. If you have a general
              question, you would like to contact the Privacy Team, the Data
              Protection Officer, or wish to exercise your rights under
              applicable law, please contact{" "}
              <a href="mailto:privacy@effem.com">privacy@effem.com</a>
            </p>

            <p>
              This privacy statement applies to Personal Data collected or
              processed by the <i>PetVoicePro</i> Application. The Privacy
              Statement applies if we collect data in person, through our
              websites, mobile applications and email. Your Personal Data
              includes information or a combination of information that could be
              used directly or indirectly to identify you ("Personal Data").
              This includes identifiers such as a name, identification number,
              location data, online identifier or factors about physical,
              physiological, genetic, mental, economic, cultural or social
              identity. Please note that the definition of Personal Data may
              vary by region. If we collect information that is considered
              Personal Data in your region, we will treat it as such in
              accordance with this Privacy Statement and applicable local law.
            </p>

            <p>We collect the following specific pieces of Personal Data:</p>

            <p>
              <strong>Personal contact details.</strong> This includes
              information such as names, addresses, phone numbers, or email
              addresses. For example, when you provide contact details during
              online registration, when doing business with us, or when you
              provide services to us. Some websites allow you to create a
              username and password to set up an account.
            </p>

            <p>
              <strong>Demographic information.</strong> For example, data such
              as age, gender, hobby or activities, tastes or preferences.
            </p>

            <p>
              <strong>Information collected from the web application.</strong>{" "}
              This includes information that is collected when you interact with
              us. For example, comments, photos or other information that you
              post through blogs, message boards or social networks.
            </p>

            <p>
              <strong>Device information.</strong> For example, Internet
              Protocol (IP) address, device identifier, your device's operating
              system, browser type, websites visited before or after visiting
              our website, pages visited and activities performed on our
              website, as well as interaction with advertisements.
            </p>

            <p>
              Any other information we have collected from you with your
              consent.
            </p>

            <p>
              The information we collect about your pet, such as species, breed,
              date of birth or treatment history, is not considered personal
              data.
            </p>

            <p>
              We may use your Personal Data for several legitimate purposes,
              including:
            </p>

            <p>
              To communicate with you and provide you with information. We may
              use your Personal Data to communicate with you, to respond to your
              inquiries or comments, or to provide you with updates and news.
              <br />
              We may also combine Personal Data to conduct internal product
              research This allows us to understand where customers are across
              our different brands and businesses and will help us improve our
              products and services.
            </p>

            <p>
              The legal bases that allow us to process your Personal Data are
              for scientific research and other legitimate business purposes. We
              may also rely on our legitimate interest to improve business and
              marketing practices. This includes but not limited to: legal
              compliance, research and analysis, combination of data, clinical
              studies, diagnostic evaluation, pet treatment and other purposes
              described above. Where we rely on our legitimate interest, we will
              carry out a legitimate interest assessment to ensure that we
              consider and balance any potential impact on your rights under
              applicable data protection laws before carrying out any data
              processing. We will not use your Personal Data for activities
              where our interests are overridden by the impact on you, unless we
              have your consent or are required or permitted by law.
            </p>

            <p>
              We may share or disclose your Personal Data for purposes that are
              consistent with this Privacy Notice in the following cases:
            </p>

            <p>
              <strong>Within the company and its family of companies.</strong>{" "}
              For example, to combine the Personal Data collected about you.
            </p>

            <p>
              <strong>With suppliers or agents.</strong> We may share your
              Personal Data with companies we have engaged to provide services
              to us. If we share Personal Data with companies that provide
              services to us, they are not permitted to use it for any other
              purpose. They must maintain the confidentiality of your Personal
              Data, unless you give your consent.
            </p>

            <p>
              To support other legitimate business purposes. This includes but
              is not limited to legal compliance, promotion of clinical trials,
              evaluation of diagnosis, and treatment of pets.
            </p>

            <p>
              We may combine anonymous or de-identified information with other
              anonymous information or not identifiable from other sources. We
              may also share aggregated and anonymous information with third
              parties, including, for example: advisors, advertisers and
              investors, for business analysis.
            </p>

            <p>
              <strong>
                I have read the privacy statement. I confirm my consent for
                collection of personal data for research.
              </strong>
            </p>
          </div>
          <p style={{ textAlign: "left", marginTop: 10 }}>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowPII(false);
              }}
            >
              Back
            </a>
          </p>
        </>
      )}

      {showResearch && (
        <>
          <h3>Pet Owner Agreement for Research</h3>
          <div className="modal-content-body text-left">
            <p>
              Research performed is in service of an undisclosed pet food
              manufacturer. The main purpose of the studies is to understand how
              various pet food products perform. Key details are highlighted
              below.
            </p>
            <h4 style={{ textAlign: "center" }}>Research Participation</h4>
            <ol style={{ paddingLeft: "20px" }}>
              <li>
                Participation in the research is voluntary and is confirmed by
                the written consent below by the owner of the animals.
              </li>
              <li>
                The test involves domestic pets (including spayed and neutered),
                which are in good health (physically healthy, not pregnant and
                not on specific diets).
              </li>
              <li>
                The start date of each test is determined, and the pet owner is
                informed about it in advance. If, for any reason, you cannot
                participate in the test, please inform the methodology
                specialist.
              </li>
              <li>
                A detailed description of the test is contained in the
                instructions that the owner receives each time with the feed.
              </li>
            </ol>

            <h4 style={{ textAlign: "center" }}>
              Company rights and obligations
            </h4>
            <ol style={{ paddingLeft: "20px" }}>
              <li>
                The company guarantees the safety of the diet used in the
                research.
              </li>
              <li>
                The company undertakes to inform all owners of any change in
                advance.
              </li>
              <li>The company has the right to change the test methodology.</li>
              <li>
                The company has the right to cancel user participation at any
                time at its discretion.
              </li>
            </ol>

            <h4 style={{ textAlign: "center" }}>
              Owners' rights and obligations
            </h4>
            <ol style={{ paddingLeft: "20px" }}>
              <li>
                Each owner has the right to withdraw from participation in the
                research at any time, notifying of their intention in advance.
              </li>
              <li>
                The owner is obliged to follow the instructions and complete the
                test in a timely and accurate manner.{" "}
              </li>
              <li>The owner guarantees the accuracy of the data reported</li>
              <li>
                The owner is obliged to promptly notify the company
                representatives of any changes in the physical condition of the
                animal (pregnancy, injury, illness, etc.), as well as the
                appearance of new animals in the house.
              </li>
            </ol>

            <p>
              I have read the terms and conditions. I confirm my consent to
              participate in this research.
            </p>
          </div>
          <p style={{ textAlign: "left", marginTop: 10 }}>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowResearch(false);
              }}
            >
              Back
            </a>
          </p>
        </>
      )}

      {!showPII && !showResearch && (
        <div className="text-left">
          <p>Dear user!</p>
          <p>
            Your privacy is very important for us! Before proceeding to
            applications please provide your consent for{" "}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowPII(true);
              }}
            >
              PII data collection
            </a>{" "}
            and{" "}
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowResearch(true);
              }}
            >
              participation in research
            </a>
            .
            <br />
            X I agree to participate in research
            <br />
            &nbsp;&nbsp; I give my consent for PII data collection
          </p>
        </div>
      )}
    </>
  );
}

export default ConsentEN;
