function jsonToForm(json) {
  const form = new FormData();
  for (const key in json) form.append(key, json[key]);
  return form;
}

function formToJson(form) {
  const json = {};
  form.forEach((value, key) => (json[key] = value));
  return json;
}

export default {
  jsonToForm,
  formToJson,
};
