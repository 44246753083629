import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { Translate } from "../../../../_artifact/i18n/Translate";

const initialState = {
  loading: false,
  totalCount: 0,
  entities: {
    languages: null,
    panelistInfo: null,
    petInfo: null,
  },
  selectedLanguage: null,
  selectedProfile: null,
  selectedTest: null,
  lastFetch: null,
  lastError: null,
  error: null,
};

// Slice
export const profilesSlice = createSlice({
  name: "profiles",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
      toast.error(action.payload.error.clientMessage);
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    cleared: () => initialState,
    // findProfiles
    languagesFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.languages = entities;
    },
    panelistInfoFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.panelistInfo = entities;
    },
    petInfoFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.petInfo = entities;
    },
    languageSelected: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.selectedLanguage = entities;
    },
  },
});

// Actions
const { actions } = profilesSlice;

export const clear = () => (dispatch) => dispatch(actions.cleared());

export const fetchLanguages = () => (dispatch) => {
  dispatch(actions.startCall());
  return getLanguages()
    .then((response) => {
      dispatch(actions.languagesFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate("PROFILE.TOAST.LANGUAGES_FETCH_FAILED");
      dispatch(actions.catchError({ error }));
    });
};

export const fetchPanelistInfo = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return getPanelistInfo(request)
    .then((response) => {
      dispatch(actions.panelistInfoFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate(
        "PROFILE.TOAST.PANELIST_INFO_FETCH_FAILED"
      );
      dispatch(actions.catchError({ error }));
    });
};

export const fetchPetInfo = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return getPetInfo(request)
    .then((response) => {
      dispatch(actions.petInfoFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate("PROFILE.TOAST.PET_INFO_FETCH_FAILED");
      dispatch(actions.catchError({ error }));
    });
};

export const selectLanguage = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return saveLanguage(request).then(() => {
    dispatch(actions.languageSelected({ entities: request.language }));
  });
};

// Crud
export const PROFILES_URL = "panelist";

function getLanguages() {
  return axios.get(`${PROFILES_URL}/getLangPref/`);
}

function getPanelistInfo(request) {
  return axios.post(`${PROFILES_URL}/panelistInfo/`, request);
}

function getPetInfo(request) {
  return axios.post(`${PROFILES_URL}/petInfo/`, request);
}

function saveLanguage(request) {
  return axios.post(`${PROFILES_URL}/setPanelistLangPref/`, request);
}
