import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_artifact/layout";
import PostWeight from "./cameras/PostWeight";
import PreWeight from "./cameras/PreWeight";
import ScanCode from "./codes/ScanCode";
import PetSelection from "./pets/PetSelection";
import Questionnaire from "./questionnaire/Questionnaire";
import TimerStart from "./timer/TimerStart";
import Finish from "./finish/Finish";
import TestDetail from "./detail/TestDetail";
import Timer from "./timer/Timer";
import TestingProgressBar from "./TestingProgressBar";
import Loader from "../../../../_artifact/components/Loader";

export default function TestingPage() {
  return (
    <Suspense fallback={<Loader />}>
      <TestingProgressBar />
      <Switch>
        <Redirect exact={true} from="/testing" to="/testing/pets" />
        <ContentRoute path="/testing/pets" component={PetSelection} />
        <ContentRoute path="/testing/codes" component={ScanCode} />
        <ContentRoute path="/testing/cameras/pre" component={PreWeight} />
        <ContentRoute path="/testing/timerstart" component={TimerStart} />
        <ContentRoute path="/testing/timer" component={Timer} />
        <ContentRoute path="/testing/cameras/post" component={PostWeight} />
        <ContentRoute path="/testing/questionnaire" component={Questionnaire} />
        <ContentRoute path="/testing/finish" component={Finish} />
        <ContentRoute path="/testing/detail" component={TestDetail} />
      </Switch>
    </Suspense>
  );
}
