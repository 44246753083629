import React, { useEffect } from "react";
import { useState } from "react";
import "antd/dist/antd.css";
import { Input, Row, Col, Button, Checkbox, Comment, List } from "antd";
import moment from "moment";

function CommentBox(props) {
  const [commentBox, setCommentBox] = useState([]);
  const [error, setError] = useState(false);
  const [sendPanelist, setSendPanelist] = useState(false);
  const [comments, setComments] = useState({});
  const [value, setValue] = useState("");

  useEffect(() => {
    console.log("Comments", loadComments());
    console.log("record comment", commentBox);
    loadComments();
  }, []);

  const loadComments = () => {
    let tempArray = [];
    if (props.comment) {
      Object.values(JSON.parse(props.comment)).map((val) =>
        tempArray.push({
          author: "Specialist",
          content: (
            <>
              <p>{val[1]}</p>
              {val[0] === 1 ? (
                <p
                  style={{
                    fontSize: "x-small",
                    color: "darkgray"
                  }}
                >
                  Sent to Panelist
                </p>
              ) : (
                <></>
              )}
            </>
          )
        })
      );
      setComments(tempArray);
      setCommentBox(tempArray);
    } else {
      setComments([]);
    }
  };

  function postComment() {
    if (value) {
      setError(false);
      props.handlePostComment(value, sendPanelist);
    } else {
      setError(true);
    }
  }

  const CommentList = ({ comments }) => (
    <div
      id="scrollableDiv"
      style={{
        maxHeight: 300,
        overflow: "auto"
      }}
    >
      <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={(props) => <Comment {...props} />}
      />
    </div>
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col md={24}>
          {comments.length > 0 && <CommentList comments={comments} />}
          <Comment
            content={
              <Input.TextArea
                onChange={(e) => setValue(e.target.value)}
                allowClear
                placeholder="Comment"
                autoSize={{ minRows: 3, maxRows: 3 }}
                value={value}
                datetime={moment().fromNow()}
              />
            }
          />
          {error && <div style={{ color: "red" }}>*Required Field</div>}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col md={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              paddingTop: "5%",
              alignItems: "center"
            }}
          >
            <Checkbox
              checked={sendPanelist}
              onChange={() => setSendPanelist(!sendPanelist)}
            >
              Send to Panelist
            </Checkbox>
            <div style={{ display: "inline-flex" }}>
              <Button
                key="back"
                style={{ border: "1px", marginRight: "1%" }}
                onClick={() => props.setCommentModalOpen(null)}
              >
                <>Close</>
              </Button>
              ,
              <Button
                key="submit"
                type="secondary-button"
                onClick={() => {
                  if (value) {
                    setComments([
                      ...comments,
                      {
                        author: "Specialist",
                        content: (
                          <>
                            <p>{value}</p>
                            {sendPanelist ? (
                              <p
                                style={{
                                  fontSize: "x-small",
                                  color: "darkgray"
                                }}
                              >
                                Sent to Panelist
                              </p>
                            ) : (
                              <></>
                            )}
                          </>
                        )
                      }
                    ]);
                    setValue("");
                    setSendPanelist(false);
                  }
                  postComment();
                }}
              >
                Post
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CommentBox;
