/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_artifact/layout";
// import MethodologistLogin from "./MethodologistLogin";
import SSOLogin from "./SSOLogin";

export function MethodologistAuthPage() {
  return (
    <>
      <Switch>
        <ContentRoute path="/methodologist/login" component={SSOLogin} />
      </Switch>
    </>
  );
}
