import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useTimer } from "react-timer-hook";
import { useWakeLock } from "react-screen-wake-lock";

import dateHelper from "../../../../../_artifact/helpers/dateHelper";
import { translate } from "../../../../../_artifact/i18n";
import { startTimer, stopTimer } from "../../_redux/timer";

function Timer({
  intl,
  timerDuration,
  isStarted,
  onSubmit,
  isFullscreen = true,
}) {
  const TIME_LIMIT = timerDuration * 60; //in seconds
  const FULL_DASH_ARRAY = 283;
  const RESET_DASH_ARRAY = `-57 ${FULL_DASH_ARRAY}`;

  const expiryTimestamp = new Date();
  // console.log("created", expiryTimestamp);
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + TIME_LIMIT);

  const dispatch = useDispatch();
  const { seconds, minutes, isRunning, start, restart } = useTimer({
    autoStart: false,
    expiryTimestamp,
    onExpire: () => {
      dispatch(stopTimer());
      console.log("onExpire", expiryTimestamp);
      onSubmit && onSubmit();
    },
  });

  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.log("Screen Wake Lock: requested!"),
    onError: () => console.log("An error happened"),
    onRelease: () => console.log("Screen Wake Lock: released!"),
  });

  const formatTime = () => {
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const [strokeDasharray, setStrokeDashArray] = useState(RESET_DASH_ARRAY);
  const [timerState, setTimerState] = useState(formatTime());

  const setCircleDashArray = () => {
    const timeLeft = dateHelper.timeToUnix(`00:${timerState}`);

    const rawTimeFraction = timeLeft / TIME_LIMIT;
    const timeFraction =
      rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);

    const circleDasharray = `${(timeFraction * FULL_DASH_ARRAY).toFixed(
      0
    )} ${FULL_DASH_ARRAY}`;
    // console.log("setCircleDashArray: ", circleDasharray);
    setStrokeDashArray(circleDasharray);
  };

  useEffect(() => {
    setTimerState(formatTime());
    setCircleDashArray();
  }, [minutes, seconds]);

  useEffect(() => {
    if (isStarted)
      if (!isRunning) {
        dispatch(startTimer());
        start();
      }
  }, [isStarted]);

  useEffect(() => {
    if (isStarted) restart(expiryTimestamp);
  }, [timerDuration]);

  useEffect(() => {
    return function cleanup() {
      release();
    };
  }, []);

  useEffect(() => {
    if (!isSupported) return;

    if (!isRunning && released === false) return release();

    if (
      isRunning &&
      (released === undefined || released === null || released === true)
    )
      request();
  }, [isRunning, seconds]);

  return (
    <>
      <div className="base-timer-wrap">
        <div className="base-timer">
          <svg
            className="base-timer__svg"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g className="base-timer__circle">
              <circle
                className="base-timer__path-elapsed"
                cx="50"
                cy="50"
                r="45"
              ></circle>
              <path
                id="base-timer-path-remaining"
                strokeDasharray={strokeDasharray}
                className="base-timer__path-remaining arc"
                d="
                        M 50, 50
                        m -45, 0
                        a 45,45 0 1,0 90,0
                        a 45,45 0 1,0 -90,0
                        "
              ></path>
            </g>
          </svg>
          <span id="base-timer-label" className="base-timer__label">
            {timerState}
          </span>
        </div>
      </div>
      {!isFullscreen && (
        <span className="minute-left">
          {timerState} {translate("TESTING.GENERAL.MINUTES_LEFT", intl)}
        </span>
      )}
    </>
  );
}

export default injectIntl(Timer);
