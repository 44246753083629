import React, { useRef, useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { getMethodogistByToken, getSSOByToken } from "..";
import Loader from "../../../../_artifact/components/Loader";
import * as auth from "./authRedux";
import { getUserByToken } from "./authRedux";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const curLink = useLocation().pathname;
  const isMethodologist = curLink.includes("methodologist");

  const { user } = useSelector((state) => state.auth, shallowEqual);
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          if (isMethodologist) {
            // const { data: user } = await getMethodogistByToken();
            await getSSOByToken()
              .then(({ data: user }) => {
                dispatch(props.fulfillMethodologist(user));
              })
              .catch((error) => {
                dispatch(props.fulfillMethodologist(undefined));
                dispatch(props.logout());
              });
          } else {
            if (user) return;
            await getUserByToken()
              .then((resp) => {
                if (resp?.data) dispatch(props.fulfillUser(resp.data));
              })
              .catch((error) => {
                console.log("Refresh Token Error -->", error);
                dispatch(props.fulfillUser(undefined));
                dispatch(props.logout());
              });
          }
        }
      } catch (error) {
        console.error("Error in Auth init -->", error);
        // toast.error(translate("AUTH.GENERAL.SESSION_EXPIRED", props.intl));
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };
    const authToken = localStorage.getItem("tok");
    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <Loader /> : <>{props.children}</>;
}

export default injectIntl(connect(null, auth.actions)(AuthInit));
