import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { ArtifactI18nProvider } from "./_artifact/i18n";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

const { PUBLIC_URL } = process.env;
// _redux.mockAxios(axios);
_redux.setupAxios(axios);

ReactDOM.render(
  <ArtifactI18nProvider>
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  </ArtifactI18nProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
