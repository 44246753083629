import React from "react";
import axios from "axios";
import { format } from "date-fns";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    axios
      .post("panelist/logspanelists/", {
        ...this.props.trackingPayload,
        frontend_error: true,
        react_log:
          "Date on : " +
          format(new Date(), "yyyy-MM-dd HH:mm:ss") +
          " On Page " +
          window.location.pathname +
          " | " +
          error.toString(),
      })
      .catch((error) => {
        console.log(error);
      });

    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
     
      return (
        <div className="error-boundary">
          <h2>Something went wrong. Please take the screenshots of this.</h2>
          <pre>{this.state.error.toString()}</pre>

          <p style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </p>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
