import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dateHelper from "../../../../_artifact/helpers/dateHelper";
import jsonHelper from "../../../../_artifact/helpers/jsonHelper";
import { toast } from "react-toastify";
import { Translate } from "../../../../_artifact/i18n/Translate";

const initialState = {
  loading: false,
  totalCount: 0,
  entities: {
    panelist_details: { language: null },
    panelist_pets: null,
    currentTestSchedule: null,
    upcomingTestSchedule: null,
    preWeight: null,
    postWeight: { request: null, response: null },
    questionnaires: null,
    answers: { responses: null, comment: null },
    petNotifs: null,
  },
  testDuration: { start_time: null, end_time: null },
  selectedPet: {
    currentTestSchedule: null,
    upcomingTestSchedule: null,
  },
  selectedPetNotif: null,
  selectedTest: null,
  route: null,
  lastFetch: null,
  lastError: null,
};

// initialState.selectedTest = {
//   test_schedule_id: 2,
//   test_name: "TST-099-M",
//   product_id: "370",
//   test_days: "Tuesday",
//   test_date: "2021-11-23",
//   wt_offered: 150.0,
//   allowed_variation: 3.0,
// };

// Slice
export const petsSlice = createSlice({
  name: "pets",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
      toast.error(action.payload.error.clientMessage);
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    cleared: () => initialState,
    // findPets
    petsFetched: (state, action) => {
      const {
        entities: { panelist_details, panelist_pets },
      } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.panelist_details = panelist_details;
      state.entities.panelist_pets = panelist_pets;
      state.lastFetch = dateHelper.now();
      // state.totalCount = totalCount;
    },
    questionnairesFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.questionnaires = entities;
    },
    petSelected: (state, action) => {
      state.selectedPet = action.payload.selectedPet;
    },
    testSelected: (state, action) => {
      state.selectedTest = action.payload.selectedTest;
    },
    preWeightUploaded: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.preWeight = entities.request;
      state.entities.questionnaires = entities.questionnaires;
    },
    postWeightUploaded: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.postWeight = entities;
    },
    answersSubmitted: (state, action) => {
      state.entities.answers = action.payload.answers;
      state.testDuration.end_time = dateHelper.now();
    },
    questionnairesUpdated: (state, action) => {
      state.entities.questionnaires = action.payload.questionnaires;
    },
    testSubmitted: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    petNotifsFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.petNotifs = entities;
    },
    petNotifsSelected: (state, action) => {
      state.selectedPetNotif = action.payload.selectedPetNotif;
    },
    languageSelected: (state, action) => {
      state.entities.panelist_details.language =
        action.payload.selectedLanguage;
    },
    testStarted: (state, action) => {
      state.testDuration.start_time = action.payload;
    },
    testCleared: (state, action) => {
      state.testDuration = initialState.testDuration;
    },
  },
});

// Actions
const { actions } = petsSlice;

export const clear = () => (dispatch) => dispatch(actions.cleared());
export const clearTest = () => (dispatch) => dispatch(actions.testCleared());

export const catchUIError = (error) => (dispatch) =>
  dispatch(actions.catchError({ error }));

export const selectPet = (selectedPet) => (dispatch) =>
  dispatch(actions.petSelected({ selectedPet }));

export const selectPetNotif = (selectedPetNotif) => (dispatch) =>
  dispatch(actions.petNotifsSelected({ selectedPetNotif }));

export const selectTest = (selectedTest) => (dispatch) => {
  dispatch(actions.testSelected({ selectedTest }));
  dispatch(actions.testCleared());
};

export const submitAnswers = (answers) => (dispatch) =>
  dispatch(actions.answersSubmitted({ answers }));

export const selectPanelistLanguage = (selectedLanguage) => (dispatch) => {
  dispatch(actions.languageSelected({ selectedLanguage }));
};

export const updateQuestionnaires = (questionnaires) => (dispatch) => {
  dispatch(actions.questionnairesUpdated({ questionnaires }));
};

export const startTest = (payload) => (dispatch) =>
  dispatch(actions.testStarted(payload));

export const fetchPets = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return findPets(request)
    .then(({ data }) => {
      data.panelist_details.panelist_first_name =
        data.panelist_details.panelist_name.split(" ")[0];
      dispatch(actions.petsFetched({ entities: data }));
    })
    .catch((error) => {
      error.clientMessage = Translate("TESTING.TOAST.PETS_FETCH_FAILED");
      dispatch(actions.catchError({ error }));
    });
};

export const fetchQuestionnaires = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return getQuestionnaires(request)
    .then((response) => {
      dispatch(actions.questionnairesFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate(
        "TESTING.TOAST.QUESTIONNAIRE_FETCH_FAILED"
      );
      dispatch(actions.catchError({ error }));
    });
};

export const uploadPreWeight = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return savePreWeight(request).then((response) => {
    const requestObject = jsonHelper.formToJson(request);
    requestObject.product_pre_img = URL.createObjectURL(
      requestObject.product_pre_img
    );
    const entities = {
      request: requestObject,
      questionnaires: response.data,
    };
    const jsonString = JSON.stringify(response.data);
    localStorage.setItem("question", jsonString);
    dispatch(actions.preWeightUploaded({ entities }));
  });
};

export const uploadPostWeight = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return savePostWeight(request).then((response) => {
    const requestObject = jsonHelper.formToJson(request);
    requestObject.product_post_img = URL.createObjectURL(
      requestObject.product_post_img
    );
    const entities = {
      request: requestObject,
      response: response.data,
    };
    dispatch(actions.postWeightUploaded({ entities }));
  });
};

export const fetchPetNotifs = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return getPetNotifs(request)
    .then((response) => {
      dispatch(actions.petNotifsFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate("TESTING.TOAST.PET_NOTIFS_FETCH_FAILED");
      dispatch(actions.catchError({ error }));
    });
};

export const submitTest = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return saveResponse(request).then(() => {
    dispatch(actions.testSubmitted());
    dispatch(actions.testCleared());
  });
};

export const saveResponseToServer = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return saveResponse(request).then(() => {
    dispatch(actions.testSubmitted());
  });
};

// Crud
export const PETS_URL = "panelist";

function findPets(request) {
  return axios.post(`${PETS_URL}/panelistPetDetails/`, request);
}

function savePreWeight(request) {
  return axios.post(`${PETS_URL}/testQuestionnaire/`, request);
}

function getQuestionnaires(request) {
  return axios.post(`${PETS_URL}/getQuestionnaire/`, request);
}

function savePostWeight(request) {
  return axios.post(`${PETS_URL}/postTestProductDetails/`, request);
}

function saveResponse(request) {
  return axios.post(`${PETS_URL}/submitResponse/`, request);
}

function getPetNotifs(request) {
  return axios.post(`${PETS_URL}/panelistPetNotif/`, request);
}
