import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import FormField from "../../../../../_artifact/components/forms/FormField";
import SubmitButton from "../../../../../_artifact/components/forms/SubmitButton";
import { fetchForgotPassword, updateAuthError } from "../..";
import jsonHelper from "../../../../../_artifact/helpers/jsonHelper";

const initialValues = {
  email: "",
};

function ForgotPassword({ history }) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({ auth: state.auth }), shallowEqual);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required field"),
  });

  const handleSubmit = (values) => {
    // console.log(values);
    dispatch(
      fetchForgotPassword(jsonHelper.jsonToForm({ email: values.email }))
    )
      .then(() => {
        history.replace("/auth/verify-otp");
      })
      .catch((error) => {
        if (error.response && error.response.data)
          error.clientMessage = error.response.data.message;
        else error.clientMessage = "Can't send OTP.";
        dispatch(updateAuthError(error));
      });
  };

  return (
    <div className="auth-wrap">
      <div className="auth-box">
        <h2 className="auth-title desktop-only">Forgot Password</h2>

        <div className="page-title-inner mobile-only">
          <Link to="/profile">
            <img src="/assets/images/left-arrow.svg" alt="" />
          </Link>
          <h2>Forgot Password</h2>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <form className="form-wrap">
            <div className="form-body">
              <div className="form-group">
                <FormField
                  name="email"
                  label="Email ID"
                  placeholder="Enter email"
                />
              </div>
            </div>
            <div className="form-footer">
              <SubmitButton title="Next" disabled={auth.loading} />
            </div>
          </form>
        </Formik>
      </div>
    </div>
  );
}

export default ForgotPassword;
