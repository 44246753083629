import React from "react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { I18nProvider } from "../_artifact/i18n";
import AuthInit from "./modules/Auth/_redux/AuthInit";
import { Routes } from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import "../App.scss";
import Loader from "../_artifact/components/Loader";
import AppInsights from "./AppInsights";

function App({ store, persistor, basename }) {
  return (
    <>
      <ToastContainer
        autoClose={2000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<Loader />}>
          <React.Suspense fallback={<Loader />}>
            <BrowserRouter basename={basename}>
              <I18nProvider>
                <AuthInit>
                  <Routes />
                </AuthInit>
              </I18nProvider>
            </BrowserRouter>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </>
  );
}

export default AppInsights(App);
