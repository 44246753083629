import { useFormikContext } from "formik";
import React from "react";

import ErrorMessage from "./ErrorMessage";

function FormField({ name, label, type, ...props }) {
  const { errors, setFieldValue, setFieldTouched, touched, values } =
    useFormikContext();

  return (
    <>
      <label>{label}</label>
      <input
        onBlur={() => setFieldTouched(name)}
        onChange={(e) => setFieldValue(name, e.target.value)}
        type={type ? type : "text"}
        className="form-control"
        value={values[name]}
        {...props}
      />
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

export default FormField;
