import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { Translate } from "../../../../_artifact/i18n/Translate";

const initialState = {
  loading: false,
  totalCount: 0,
  entities: {
    rewardNotifs: null,
    rewardPoints: null,
  },
  selectedPetRewards: {
    points: 0,
    successful_test: 0,
    unsuccessful_test: 0,
  },
  lastFetch: null,
  lastError: null,
};

// Slice
export const rewardsSlice = createSlice({
  name: "rewards",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
      toast.error(action.payload.error.clientMessage);
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    cleared: () => initialState,
    // findRewards
    rewardNofitsFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.rewardNotifs = entities;
    },
    rewardPointsFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities.rewardPoints = entities;
    },
    rewardNotifiedUpdated: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    petRewardsSelected: (state, action) => {
      const { selectedPetRewards } = action.payload;
      state.selectedPetRewards = selectedPetRewards
        ? selectedPetRewards
        : {
            points: 0,
            successful_test: 0,
            unsuccessful_test: 0,
          };
    },
  },
});

// Actions
const { actions } = rewardsSlice;

export const clear = () => (dispatch) => dispatch(actions.cleared());

export const fetchRewardNotifs = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return getRewardNotifs(request)
    .then((response) => {
      dispatch(actions.rewardNofitsFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate(
        "REWARDS.TOAST.REWARD_NOTIFS_FETCH_FAILED"
      );
      dispatch(actions.catchError({ error }));
    });
};

export const fetchRewardPoints = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return getRewardPoints(request)
    .then((response) => {
      const entities = response.data;
      dispatch(actions.rewardPointsFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate(
        "REWARDS.TOAST.REWARD_POINTS_FETCH_FAILED"
      );
      dispatch(actions.catchError({ error }));
    });
};

export const updateRewardNotified = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return postRewardNotified(request)
    .then((response) => {
      dispatch(actions.rewardNofitsFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate(
        "REWARDS.TOAST.REWARD_NOTIFS_UPDATE_FAILED"
      );
      dispatch(actions.catchError({ error }));
    });
};

export const selectPetRewards = (selectedPetRewards) => (dispatch) => {
  dispatch(actions.petRewardsSelected({ selectedPetRewards }));
};

// Crud
export const REWARDS_URL = "panelist";

function getRewardNotifs(request) {
  return axios.post(`${REWARDS_URL}/rewardNotif/`, request);
}

function getRewardPoints(request) {
  return axios.post(`${REWARDS_URL}/rewardPoints/`, request);
}

function postRewardNotified(request) {
  return axios.post(`${REWARDS_URL}/rewardNotified/`, request);
}
