import { useFormikContext } from "formik";
import React from "react";

function SubmitButton({ title, disabled }) {
  const { handleSubmit } = useFormikContext();

  return (
    <button
      className="btn btn-primary btn-block"
      type="submit"
      onClick={handleSubmit}
      disabled={disabled}
    >
      {title}
    </button>
  );
}

export default SubmitButton;
