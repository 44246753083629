export function processFaqs(faqs) {
  if (!faqs) return null;
  const faqsInStore = JSON.parse(JSON.stringify(faqs));

  return Object.keys(faqsInStore).map((header1) => ({
    header1,
    value1: Object.keys(faqsInStore[header1]).map((header2) => ({
      header2,
      value2: faqsInStore[header1][header2],
    })),
  }));
}
