import React from "react";
import { Modal } from "antd";

function AppModal({
  isOpen,
  action,
  title,
  description,
  message,
  image,
  icon,
  onClose,
  onSubmit,
  component,
  messageContent,
}) {
  return (
    <Modal
      centered
      footer={null}
      visible={isOpen}
      closable={false}
      className="borderRadius"
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "1rem",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      {component && component}
      {message && <h2 className="modal-title">{message}</h2>}
      {image && <img src={image} alt="" />}
      {icon && <i className={icon}></i>}
      {title && <h2 className="modal-title">{title}</h2>}
      {description && <p>{description}</p>}
      {messageContent && <div>{messageContent}</div>}
      <button
        className="btn btn-primary btn-block"
        onClick={() => {
          onClose();
          onSubmit && onSubmit();
        }}
      >
        {action}
      </button>
    </Modal>
  );
}

export default AppModal;
