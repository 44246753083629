import React from "react";
import { Layout, Row, Col } from "antd";
import { useState } from "react";
// import Header from '../header';
import "./wrapper.scss";
import { current } from "@reduxjs/toolkit";

function Wrapper(props) {
  return (
    <Layout>
      {props.headerChildren}
      <Layout
        style={{
          minHeight: "calc(100vh - 66px)",
          // overflow: "hidden"
        }}
      >
        <Layout>{props.children}</Layout>
      </Layout>
    </Layout>
  );
}

export default Wrapper;
