import { useFormikContext } from "formik";
import React, { useState } from "react";

import ErrorMessage from "./ErrorMessage";

function PasswordField({ name, label, ...props }) {
  const { errors, setFieldValue, setFieldTouched, touched, values } =
    useFormikContext();
  const [active, setActive] = useState(false);

  return (
    <>
      <label>{label}</label>
      <div className="input-group">
        <input
          onBlur={() => setFieldTouched(name)}
          onChange={(e) => setFieldValue(name, e.target.value)}
          type={active ? "text" : "password"}
          className="form-control"
          value={values[name]}
          {...props}
        />
        <i className={`icon-eye ${active ? "active" : ""}`} onClick={() => setActive(!active)}></i>
      </div>
      <ErrorMessage error={errors[name]} visible={touched[name]} />
    </>
  );
}

export default PasswordField;
