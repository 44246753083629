import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import Navbar from "./Navbar";
import Loader from "../_artifact/components/Loader";
import { MethodologistAuthPage } from "./modules/Auth/pages/MethodologistAuthPage";
import * as authRedux from "./modules/Auth/_redux/authRedux";
import Modal from "../_artifact/components/Modal";
import { Translate } from "../_artifact/i18n/Translate";
import { getSelectedLanguage } from "../_artifact/i18n";
import ConsentEN from "./consents/ConsentEN";
import ConsentES from "./consents/ConsentES";
import ErrorBoundary from "./ErrorBoundary";
import { compareLoginTime } from "./utils";
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(true);
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const users = useSelector((state) => state.auth.user);
  const authLading = state.auth.loading;
  // const isAuthorized = state.auth.user ? true : false;
  const isSpecialist = state.auth.user?.isSpecialist;
  const isConsent = state.auth.user?.consent_flag;
  const { auth, pets, previousTests, supports, profiles, rewards, tracking } =
    state;

  const curLink = useLocation().pathname;
  const isMethodologist = curLink.includes("methodologist");

  // console.log("isAuthorized", isAuthorized);
  // console.log("isSpecialist", isSpecialist);
  // console.log("isMethodologist", isMethodologist);
  useEffect(() => {
    const token = localStorage.getItem("tok");
    if (token) {
      var verfiyTime = compareLoginTime(120);
      setIsAuth(verfiyTime);
    } else {
      setIsAuth(false);
    }
  }, [users]);

  let isConfig = false;
  const selectedLanguage = getSelectedLanguage();
  if (selectedLanguage) isConfig = true;
  return (
    <ErrorBoundary trackingPayload={tracking.trackingPayload}>
      <Switch>
        {users && !isAuth && <Loader />}
        {isMethodologist || isSpecialist ? (
          isSpecialist ? (
            <>
              <BasePage />
              <Route path="/methodologist/logout" component={Logout} />
              {(curLink === "/methodologist/login" ||
                curLink === "/methodologist") && (
                <Redirect
                  exact
                  from="/methodologist/login"
                  to="/methodologist/home"
                />
              )}
            </>
          ) : (
            <>
              <Route>
                {auth.loading && <Loader />}
                <MethodologistAuthPage />
                <Redirect
                  exact={true}
                  from="/methodologist"
                  to="/methodologist/login"
                />
              </Route>
            </>
          )
        ) : !users && !isAuth ? (
          <Route>
            {auth.loading && <Loader />}
            <AuthPage />
            <Redirect from="/" exact to="/auth/login" />
          </Route>
        ) : (
          users &&
          isAuth && (
            <>
              {(authLading ||
                pets.loading ||
                previousTests.loading ||
                supports.loading ||
                profiles.loading ||
                rewards.loading) && <Loader />}
              <Navbar />
              <BasePage />
              <Route exact={true} path="/logout" component={Logout} />
              {(curLink === "/" || curLink === "/auth/login") && (
                <Redirect exact to="/home" />
              )}
              {isConfig && (
                <Modal
                  isOpen={isConsent !== "Y"}
                  action={Translate("APP.BUTTON.ACCEPT")}
                  messageContent={
                    selectedLanguage === "es" ? <ConsentES /> : <ConsentEN />
                  }
                  onClose={() => {}}
                  onSubmit={() => {
                    dispatch(authRedux.actions.startCall());
                    authRedux
                      .getOneTrust()
                      .then(() => {
                        authRedux
                          .getUserByToken()
                          .then(({ data: user }) => {
                            dispatch(authRedux.actions.fulfillUser(user));
                            dispatch(authRedux.actions.callCompleted());
                          })
                          .catch(() =>
                            dispatch(authRedux.actions.callCompleted())
                          );
                      })
                      .catch(() => dispatch(authRedux.actions.callCompleted()));
                  }}
                />
              )}
            </>
          )
        )}
      </Switch>
    </ErrorBoundary>
  );
}
