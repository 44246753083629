import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { downloadExcel } from "../../../../_artifact/helpers/tableHelper";
import { panelist_col } from "../../../../_artifact/helpers/tableHelper";

const initialState = {
  loader: false,
  buttonLoader: false,
  totalCount: 0,
  panelistData: [],
  lastFetch: null,
};

// Slice
export const panelistDataSlice = createSlice({
  name: "panelistSlice",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loader = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loader = true;
    },
    cleared: () => initialState,
    buttonStartCall: (state, action) => {
      state.error = null;
      state.buttonLoader = true;
    },
    buttonEndCall: (state, action) => {
      state.error = null;
      state.buttonLoader = false;
    },
    // findResults
    panelistDataFetched: (state, action) => {
      const { panelistData } = action.payload;
      state.loader = false;
      state.error = null;
      state.panelistData = panelistData;
    },
  },
});

// Actions
const { actions } = panelistDataSlice;

export const clear = () => (dispatch) => dispatch(actions.cleared());

export const fetchPanelistData = () => (dispatch) => {
  dispatch(actions.startCall());
  return panelistData()
    .then((response) => {
      const panelistData = response.data;
      dispatch(actions.panelistDataFetched({ panelistData }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find results";
      dispatch(actions.catchError({ error }));
    });
};

export const fetchPanelistDataExport = () => (dispatch) => {
  dispatch(actions.buttonStartCall());
  return panelistData()
    .then((response) => {
      const panelistData = response.data;
      const panelistColumns = panelist_col.filter(
        ({ title }) => title !== "Pet Image"
      );
      downloadExcel("Panelist Data", panelistData, panelistColumns);
      dispatch(actions.buttonEndCall());
    })
    .catch((error) => {
      error.clientMessage = "Can't find results";
      dispatch(actions.catchError({ error }));
    });
};

// Crud
export const RESULTS_URL = "specialist";

function panelistData() {
  return axios.get(`${RESULTS_URL}/panelistDetails/`);
}
