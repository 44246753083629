import React, { useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { format } from "date-fns";

import Modal from "../../../../../_artifact/components/Modal";
import jsonHelper from "../../../../../_artifact/helpers/jsonHelper";
import { translate } from "../../../../../_artifact/i18n";
import * as petsActions from "../../_redux/pets";
import { fetchPreviousTestDetails } from "../../_redux/previousTests";
import { trackingLog } from "../../../Tracking/_redux/tracking";
import * as logsPanelistsActions from "../../../Tracking/_redux/tracking";
import RedirectModel from "../../../../../_artifact/components/RedirectModel";

function Finish({ intl, history }) {
  const { state } = useSelector((state) => ({ state }), shallowEqual);
  const { testDetails } = state.previousTests;
  const { answers } = state.pets.entities;
  const [comment, setComment] = useState(answers?.comment || "");
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const selectedTest = useRef({});
  const dispatch = useDispatch();

  const { trackingPayload } = useSelector(
    (state) => state.tracking,
    shallowEqual
  );

  useEffect(() => {
    const jsonString = localStorage.getItem("selectedTest");
    const myObject = JSON.parse(jsonString);
    selectedTest.current = myObject;
    if (!myObject?.test_schedule_id) {
      history.replace("testing/codes");
    } else {
      dispatch(
        fetchPreviousTestDetails({
          panelist_id: myObject?.panelist_id,
          test_schedule_id: myObject?.test_schedule_id,
          product_id: myObject?.product_id,
        })
      );
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!testDetails?.product_pre_img) {
      toast.error(translate("TESTING.TOAST.PRE_POST_REQUIRED", intl));
      return;
    }

    if (!testDetails?.product_post_img) {
      toast.error(translate("TESTING.TOAST.PRE_POST_REQUIRED", intl));
      return;
    }
    const jsonString = localStorage.getItem("anwserObj");
    let values = JSON.parse(jsonString);

    values.add_comments = comment;

    var retry = 1;
    const api = () => {
      dispatch(petsActions.submitTest(jsonHelper.jsonToForm(values)))
        .then(() => {
          setIsOpen(true);
          dispatch(
            trackingLog({
              ...trackingPayload,
              additional_comment: comment,
              sumitted_test: 1,
              submitted_test_time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
            })
          );
        })
        .catch((error) => {
          dispatch(
            trackingLog({
              ...trackingPayload,
              api_log:
                error["stack"] +
                " | Finish " +
                format(new Date(), "yyyy-MM-dd HH:mm:ss") +
                " |" +
                error.message,
            })
          );
          retry++;
          if (retry < 3) {
            api();
          }
          if (retry === 3) setOpen(true);
          error.clientMessage = translate(
            "TESTING.TOAST.TEST_SUBMIT_FAILED",
            intl
          );
          dispatch(petsActions.catchUIError(error));
        });
    };
    api();
  };

  return (
    <div className="inner-page">
      <div className="test-box">
        <h3 className="test-box-title desktop-only">
          {translate("TESTING.GENERAL.GREAT_JOB", intl)}
        </h3>
        <div className="finish-box">
          <div className="captured-weight">
            <div className="response-text check-arrow">
              <h4>{translate("TESTING.HEADER.PRE_WEIGHT", intl)}</h4>
              <p>{testDetails?.product_pre_wt} gms</p>
            </div>
            <div className="c-image">
              <img
                src={testDetails?.product_pre_img}
                alt={translate("TESTING.IMAGEUPLOAD.MSG", intl)}
              />
            </div>
          </div>
        </div>

        <div className="finish-box">
          <div className="response-text">
            <h4 className="check-arrow">
              {translate("TESTING.HEADER.OBSERVATION", intl)}
            </h4>
            {answers.responses?.map(({ title, response }, index) => (
              <p key={index}>
                {index + 1}. {title}: <strong>{response}</strong>
              </p>
            ))}
          </div>
        </div>

        <div className="finish-box">
          <div className="captured-weight">
            <div className="response-text check-arrow">
              <h4>{translate("TESTING.HEADER.POST_WEIGHT", intl)}</h4>
              <p>{testDetails?.product_post_wt} gms</p>
            </div>
            <div className="c-image">
              <img
                src={testDetails?.product_post_img}
                alt={translate("TESTING.IMAGEUPLOAD.MSG", intl)}
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>{translate("TESTING.GENERAL.COMMENTS_OPTIONAL", intl)}</label>
          <textarea
            placeholder={translate("TESTING.GENERAL.ADDITIONAL_COMMENTS", intl)}
            rows="5"
            className="form-control"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>

        <div>
          <button className="btn btn-primary btn-block" onClick={handleSubmit}>
            {translate("TESTING.BUTTON.SUBMIT", intl)}
          </button>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        action={translate("TESTING.BUTTON.VIEW_SCHEDULE", intl)}
        title={translate("TESTING.GENERAL.YOUR_TEST_WAS_SUCCESSFUL", intl)}
        icon="icon-circle-check text-success font-200"
        onClose={() => {
          dispatch(
            trackingLog(
              {
                ...trackingPayload,
                redirected_to_home: 1,
              },
              false
            )
          );
          dispatch(petsActions.clear());
          dispatch(logsPanelistsActions.clear(trackingPayload));
          // window.location.href = "/";
          history.push("/");
        }}
      />
      <RedirectModel history={history} isOpen={open} />
    </div>
  );
}

export default injectIntl(Finish);
