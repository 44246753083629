import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dateHelper from "../../../../_artifact/helpers/dateHelper";
import jsonHelper from "../../../../_artifact/helpers/jsonHelper";
import { openNotificationWithIcon } from "../../../../_artifact/helpers/tableHelper";
import { testResultsFetched } from "./results";

const initialState = {
  loader: false,
  commentLoader: false,
  submitLoader: false,
  error: null,
  resultsDetailedData: [],
  kpiData: [],
};

// Slice
export const testResultsDetailedSlice = createSlice({
  name: "testResultsDetailed",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loader = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.loader = true;
    },
    endCall: (state, action) => {
      state.error = null;
      state.loader = false;
    },
    cleared: () => initialState,
    startCommentCall: (state, action) => {
      state.commentLoader = true;
    },
    endCommentCall: (state, action) => {
      state.commentLoader = false;
    },
    startSubmitCall: (state, action) => {
      state.submitLoader = true;
    },
    endSubmitCall: (state, action) => {
      state.submitLoader = false;
    },
    buttonStartCall: (state, action) => {
      state.buttonLoader = true;
    },
    buttonEndCall: (state, action) => {
      state.buttonloader = false;
    },
    startStatusCall: (state, action) => {
      state.statusloader = true;
    },
    endStatusCall: (state, action) => {
      state.statusloader = false;
    },
    // findResults
    testResultsDetailedFetch: (state, action) => {
      const { resultsDetailedData } = action.payload;
      state.loader = false;
      state.error = null;
      state.resultsDetailedData = resultsDetailedData;
    },
    testKPIFetched: (state, action) => {
      const { kpiData } = action.payload;
      state.loader = false;
      state.error = null;
      state.kpiData = kpiData;
    },
  },
});

// Actions
const { actions } = testResultsDetailedSlice;

export const clear = () => (dispatch) => dispatch(actions.cleared());

export const fetchTestResultsDetailed = (text) => (dispatch) => {
  let body = {
    test_id: text,
  };
  dispatch(actions.startCall());
  return testResultsDetailed(body)
    .then((response) => {
      const resultsDetailedData = response.data;
      console.log("results data", resultsDetailedData);
      dispatch(actions.testResultsDetailedFetch({ resultsDetailedData }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find results detailed";
      dispatch(actions.catchError({ error }));
    });
};

export const fetchTestKPIs = (text) => (dispatch) => {
  let body = {
    test_id: text,
  };
  dispatch(actions.startCall());
  return testKPIDetails(body)
    .then((response) => {
      const kpiData = response.data;
      dispatch(actions.testKPIFetched({ kpiData }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find results";
      dispatch(actions.catchError({ error }));
    });
};

export const addCommentTest =
  (dta, comment, sendComment) => (dispatch, getState) => {
    let newData = getState().testResultsDetailed.resultsDetailedData.map(
      (val) => {
        if (val.row_id === dta.row_id) {
          let existingComments = JSON.parse(val.specialist_comments);
          let newComments = {
            ...existingComments,
            [Date.now()]: [sendComment ? 1 : 0, comment.toString()],
          };
          return {
            ...val,
            specialist_comments: JSON.stringify(newComments),
          };
        } else {
          return { ...val };
        }
      }
    );

    var bodyFormData = new FormData();
    bodyFormData.append("test_id", dta.test_id);
    bodyFormData.append("product_id", dta.product_id);
    bodyFormData.append("pet_name", dta.pet_name);
    bodyFormData.append("country", "Mexico");
    bodyFormData.append("specialist_comment", comment.toString());
    bodyFormData.append("save_send", sendComment ? 1 : 0);
    // console.log("Just Before API Call")
    dispatch(actions.startCommentCall());
    return addComment(bodyFormData)
      .then((response) => {
        if (!response.data.includes("error")) {
          const resultsDetailedData = newData;
          dispatch(actions.testResultsDetailedFetch({ resultsDetailedData }));
          actions.endCommentCall();
          // console.log("Response", response.data)
          openNotificationWithIcon("success", "Comment was saved");
        } else {
          actions.endCommentCall();
          // console.log("Error", response)
          openNotificationWithIcon(
            "error",
            "Operation failed. Please try again or reach out to support"
          );
        }
      })
      .catch((error) => {
        error.clientMessage = "Can't find results";
        openNotificationWithIcon(
          "error",
          "Operation failed. Please try again or reach out to support"
        );
        dispatch(actions.catchError({ error }));
      });
  };

export const scoreTestDetailed =
  (rows, status, data, country) => (dispatch) => {
    let product_pet = {};
    // const { state } = useSelector((state) => ({ state }), shallowEqual);
    console.log("Score Data", data, rows);
    let newData = data?.map((val) => {
      if (rows.includes(val.row_id) && status === "Approved") {
        product_pet[val.product_id]
          ? product_pet[val.product_id].push(val.pet_name)
          : (product_pet[val.product_id] = [val.pet_name]);
        return {
          ...val,
          score: "Approved",
        };
      } else if (rows.includes(val.row_id) && status === "Rejected") {
        product_pet[val.product_id]
          ? product_pet[val.product_id].push(val.pet_name)
          : (product_pet[val.product_id] = [val.pet_name]);
        return {
          ...val,
          score: "Rejected",
        };
      }
      return {
        ...val,
      };
    });

    let dta = newData.filter((val) => rows.includes(val.row_id));

    console.log(
      "Score Test",
      new Set(dta.map((val) => val.test_id)),
      country,
      new Set(dta.map((val) => val.score)),
      product_pet
    );
    console.log("Score Data Test", newData);

    const score = () => {
      if (
        [...new Set(dta.map((val) => val.score))].toString().toLowerCase() ===
        "approved"
      ) {
        return "1";
      } else {
        return "2";
      }
    };

    var bodyFormData = new FormData();
    bodyFormData.append(
      "test_id",
      [...new Set(dta.map((val) => val.test_id))].toString()
    );
    bodyFormData.append("product_pet", JSON.stringify(product_pet));
    bodyFormData.append("country", "Mexico");
    bodyFormData.append("score", score());
    dispatch(actions.startStatusCall());
    return scoreTest(bodyFormData)
      .then((response) => {
        dispatch(actions.endStatusCall());
        const resultsDetailedData = newData;
        openNotificationWithIcon("success", response.data);
        dispatch(actions.testResultsDetailedFetch({ resultsDetailedData }));
        // dispatch();
      })
      .catch((error) => {
        error.clientMessage = "Can't approve/reject response(s)";
        dispatch(actions.catchError({ error }));
      });
  };

export const submitReviewTest = (text, data) => (dispatch, getState) => {
  let tresultsData = getState().testresults.resultsdata;
  let newData = tresultsData?.map((val) => {
    if (text.includes(val.test_id)) {
      return {
        ...val,
        test_status_type: "Previous Test",
      };
    }
    return {
      ...val,
    };
  });
  console.log("Submit Review", newData);
  var bodyFormData = new FormData();
  bodyFormData.append("test_id", text);
  dispatch(actions.startCall());
  return submitReview(bodyFormData)
    .then((response) => {
      const resultsdata = newData;
      dispatch(testResultsFetched({ resultsdata }));
      dispatch(actions.endCall());
      openNotificationWithIcon("success", response.data.message);
    })
    .catch((error) => {
      error.clientMessage = "Can't find results";
      if (error.response) {
        openNotificationWithIcon("error", error.response.data.message);
      } else {
        openNotificationWithIcon("error", error.response);
      }
      dispatch(actions.catchError({ error }));
    });
};

// export const fetchTestDetailedDataExport = (text, cols) => (dispatch) => {
//     let body={
//         "test_id": text
//     }
//     dispatch(actions.buttonStartCall());
//     return testResultsDetailed(body)
//       .then((response) => {
//         const exportData = response.data;
//         downloadExcel(exportData, cols);
//         dispatch(actions.buttonEndCall());
//       })
//       .catch((error) => {
//         error.clientMessage = "Can't find results";
//         dispatch(actions.catchError({ error }));
//       });
//   };

// Crud
export const RESULTS_URL = "specialist";

function testResultsDetailed(body) {
  return axios.post(`${RESULTS_URL}/testDetailedView/`, body);
}

function testKPIDetails(body) {
  return axios.post(`${RESULTS_URL}/testKPIDetails/`, body);
}

function addComment(body) {
  return axios({
    method: "post",
    url: `${RESULTS_URL}/addComment/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function scoreTest(body) {
  return axios({
    method: "post",
    url: `${RESULTS_URL}/scoreTest/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function submitReview(body) {
  return axios({
    method: "post",
    url: `${RESULTS_URL}/submitReview/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
}
