import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import SubmitButton from "../../../../../_artifact/components/forms/SubmitButton";
import { fetchResetPassword, updateAuthError } from "../..";
import jsonHelper from "../../../../../_artifact/helpers/jsonHelper";
import PasswordField from "../../../../../_artifact/components/forms/PasswordField";
import ErrorMessage from "../../../../../_artifact/components/forms/ErrorMessage";
import config from "../../../../config";

const initialValues = {
  new_password: "",
  confirm_password: "",
};

function ResetPassword({ history }) {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const { auth } = useSelector((state) => ({ auth: state.auth }), shallowEqual);

  const validationSchema = Yup.object().shape({
    new_password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required Field"),
    confirm_password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Required Field")
      .test("equal", "Passwords do not match!", function (v) {
        const ref = Yup.ref("new_password");
        return v === this.resolve(ref);
      }),
  });

  const handleSubmit = (values, { setStatus }) => {
    console.log(values);
    dispatch(
      fetchResetPassword(
        jsonHelper.jsonToForm({
          authId: auth.forgotUser.authId,
          email_id: auth.forgotUser.email,
          new_password: values.new_password,
        })
      )
    )
      .then(() => {
        toast.success("Password reset successfully.");
        history.replace("/");
      })
      .catch((error) => {
        if (error.response && error.response.data)
          error.clientMessage = error.response.data.message;
        else error.clientMessage = "Can't reset password";
        dispatch(updateAuthError(error));
        const { unauthorized, internalServerError } = config.errorStatus;
        if (
          (error.response && error.response.status === unauthorized) ||
          error.response.status === internalServerError
        )
          setStatus(
            <ul>
              <li>Must have 9-64 characters.</li>
              <li>Must have atleast 1 upper case letter.</li>
              <li>Must have atleast 1 lower case letter.</li>
              <li>Must have atleast 1 special character.</li>
            </ul>
          );
      });
  };

  return (
    <div className="auth-wrap">
      <div className="auth-box">
        <h2 className="auth-title desktop-only">Reset Password</h2>

        <div className="page-title-inner mobile-only">
          <Link to="/profile">
            <img src="/assets/images/left-arrow.svg" alt="" />
          </Link>
          <h2>Reset Password</h2>
        </div>

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ status }) => (
            <form className="form-wrap">
              <div className="form-body">
                <ErrorMessage error={status} visible={status} />
                <div className="form-group">
                  <PasswordField
                    name="new_password"
                    label="New Password"
                    placeholder="Enter Your Password"
                  />
                </div>
                <div className="form-group">
                  <PasswordField
                    name="confirm_password"
                    label="Confirm Password"
                    placeholder="Enter Your Password"
                  />
                </div>
              </div>
              <div className="form-footer">
                <SubmitButton title="Reset" disabled={auth.loading} />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ResetPassword;
