import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import * as petsActions from "../../Testing/_redux/pets";
import * as previousTestsActions from "../../Testing/_redux/previousTests";
import * as timerActions from "../../Testing/_redux/timer";
import * as supportsActions from "../../Support/_redux/supports";
import * as profilesActions from "../../Profile/_redux/profiles";
import * as rewardsActions from "../../Rewards/_redux/rewards";
import * as resultsActions from "../../Methodologist/_redux/results";
import * as resultsDetailedActions from "../../Methodologist/_redux/resultsDetailed";
import * as panelistsActions from "../../Methodologist/_redux/panelists";


function Refresh() {
  const dispatch = useDispatch();
  useEffect(() => {
    // Panelist
    // dispatch(petsActions.clear()); // Don't clear pets state on refresh
    // dispatch(previousTestsActions.clear());
    dispatch(timerActions.clear());
    dispatch(supportsActions.clear());
    dispatch(profilesActions.clear());
    dispatch(rewardsActions.clear());

    // Methodologist
    dispatch(resultsActions.clear());
    dispatch(resultsDetailedActions.clear());
    dispatch(panelistsActions.clear());
  }, [dispatch]);

  return <></>;
}

export default Refresh;
