export const MethodologistStatusTitles = {
  "Live Test": "live",
  "Previous Test": "previous",
  "To be reviewed": "reviewed",
};

export const NotifsDaysTitles = {
  today: "Today",
  yesterday: "Yesterday",
  older: "Older",
};
