import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { format } from "date-fns";
// import { toast } from "react-toastify";
// import { Translate } from "../../../../_artifact/i18n/Translate";

const initialState = {
  loading: false,
  trackingPayload: {
    panelist_email_id: "",
    // logged_in_time: "2022-12-27 16:18:58.5540000",
    logged_in_time: "",
    at_homepage: 0,
    product_id_page: 0,
    pet_name: "",
    test_schedule_id: "",
    product_id: "",
    preweight_page: 0,
    start_image_url: "",
    initial_weight: 0,
    timer_page: 0,
    started_timer: 0,
    question_responses: "",
    postweight_page: 0,
    final_weight: 0,
    end_image_url: "",
    additional_comment: "",
    sumitted_test: 0,
    submitted_test_time: "",
    redirected_to_home: 0,
  },
};

// Slice
export const trackingSlice = createSlice({
  name: "tracking",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
      console.log("Tracking Error: ", action.payload.error.clientMessage);
      // toast.error(action.payload.error.clientMessage);
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    cleared: (state, action) => action.payload.copy,
    // findTracking
    trackingLogged: (state, action) => {
      const { body, update } = action.payload;
      state.loading = false;
      state.error = null;
      if (update) {
        state.trackingPayload = body;
        console.log("action.payload", action.payload);
      }
    },
    setTrackingData: (state, action) => {
      state = { ...state, ...action.payload };
    },
  },
});

// Actions
const { actions } = trackingSlice;
export const { trackingLogged } = actions;

export const clear = (body) => (dispatch) => {
  const copy = {
    loading: false,
    trackingPayload: {
      panelist_email_id: body?.panelist_email_id || "",
      // logged_in_time: "2022-12-27 16:18:58.5540000",
      logged_in_time: body?.logged_in_time || "",
      at_homepage: 1,
      product_id_page: 0,
      pet_name: "",
      test_schedule_id: "",
      product_id: "",
      preweight_page: 0,
      start_image_url: "",
      initial_weight: 0,
      timer_page: 0,
      started_timer: 0,
      question_responses: "",
      postweight_page: 0,
      final_weight: 0,
      end_image_url: "",
      additional_comment: "",
      sumitted_test: 0,
      submitted_test_time: "",
      redirected_to_home: 0,
    },
  };
  dispatch(actions.cleared({ copy }));
};
export const trackingLog =
  (body, update = true) =>
  (dispatch) => {
    dispatch(actions.startCall());
    return axios
      .post("panelist/logspanelists/", body)
      .then(() => {
        dispatch(actions.trackingLogged({ body, update }));
      })
      .catch((error) => {
        // error.clientMessage = Translate("PROFILE.TOAST.LANGUAGES_FETCH_FAILED");
        error.clientMessage = "Tracking Failed";
        dispatch(actions.catchError({ error }));
      });
  };
