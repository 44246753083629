import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import dateHelper from "../../../../_artifact/helpers/dateHelper";
import { Translate } from "../../../../_artifact/i18n/Translate";

const initialState = {
  loading: false,
  totalCount: 0,
  entities: null,
  testDetails: null,
  selectedPreviousTest: null,
  lastFetch: null,
  lastError: null,
};

// const callTypes = {
//   list: "list",
//   action: "action",
// };
//Shoaib

// Slice
export const previousTestsSlice = createSlice({
  name: "previousTests",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loading = false;
      toast.error(action.payload.error.clientMessage);
    },
    startCall: (state, action) => {
      state.error = null;
      state.loading = true;
    },
    cleared: () => initialState,
    // findPreviousTests
    previousTestsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.lastFetch = dateHelper.now();
    },
    previousTestDetailsFetched: (state, action) => {
      const { entities } = action.payload;
      state.loading = false;
      state.error = null;
      state.testDetails = entities;
    },
    previousTestSelected: (state, action) => {
      state.selectedPreviousTest = action.payload.selectedPreviousTest;
    },
  },
});

// Actions
const { actions } = previousTestsSlice;

export const clear = () => (dispatch) => dispatch(actions.cleared());

export const fetchPreviousTests = (request) => (dispatch) => {
  dispatch(actions.startCall());
  // console.log(Date.now());
  return findPreviousTests(request)
    .then((response) => {
      // console.log("fetchPreviousTests", response);
      // if (response.data)
      const entities = response.data;
      const totalCount = response.data.length;
      // const { totalCount, entities } = response.data;
      dispatch(actions.previousTestsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = Translate(
        "TESTING.TOAST.PREVIOUS_TESTS_FETCH_FAILED"
      );
      dispatch(actions.catchError({ error }));
    });
};

export const fetchPreviousTestDetails = (request) => (dispatch) => {
  dispatch(actions.startCall());
  return findPreviousTestDetails(request)
    .then((response) => {
      dispatch(actions.previousTestDetailsFetched({ entities: response.data }));
    })
    .catch((error) => {
      error.clientMessage = Translate(
        "TESTING.TOAST.PREVIOUS_TESTS_DETAILS_FETCH_FAILED"
      );
      dispatch(actions.catchError({ error }));
    });
};

export const selectPreviousTest = (selectedPreviousTest) => (dispatch) =>
  dispatch(actions.previousTestSelected({ selectedPreviousTest }));

// Crud
export const PREVIOUS_TESTS_URL = "panelist";

function findPreviousTests(request) {
  return axios.post(`${PREVIOUS_TESTS_URL}/previousTestScheduleList/`, request);
}

function findPreviousTestDetails(request) {
  return axios.post(`${PREVIOUS_TESTS_URL}/previousTestResponse/`, request);
}
