import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { petsSlice } from "../app/modules/Testing/_redux/pets";
import { previousTestsSlice } from "../app/modules/Testing/_redux/previousTests";
import { timerSlice } from "../app/modules/Testing/_redux/timer";
import { supportsSlice } from "../app/modules/Support/_redux/supports";
import { profilesSlice } from "../app/modules/Profile/_redux/profiles";
import { rewardsSlice } from "../app/modules/Rewards/_redux/rewards";
import { trackingSlice } from "../app/modules/Tracking/_redux/tracking";
import {
  resultsSlice,
  testresultsSlice,
} from "../app/modules/Methodologist/_redux/results";
import { testResultsDetailedSlice } from "../app/modules/Methodologist/_redux/resultsDetailed";
import { panelistDataSlice } from "../app/modules/Methodologist/_redux/panelists";
import { manageSlice } from "../app/modules/Methodologist/_redux/manage";
import { overviewSlice } from "../app/modules/Methodologist/_redux/overview";

export const rootReducer = combineReducers({
  auth: auth.reducer,

  // Panelist
  pets: petsSlice.reducer,
  previousTests: previousTestsSlice.reducer,
  timer: timerSlice.reducer,
  supports: supportsSlice.reducer,
  profiles: profilesSlice.reducer,
  rewards: rewardsSlice.reducer,
  tracking: trackingSlice.reducer,

  // Methodologist
  overviewData: overviewSlice.reducer,
  testresults: testresultsSlice.reducer,
  testResultsDetailed: testResultsDetailedSlice.reducer,
  panelistData: panelistDataSlice.reducer,
  manageData: manageSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
