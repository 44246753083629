import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

import dateHelper from "../../../../_artifact/helpers/dateHelper";
import jsonHelper from "../../../../_artifact/helpers/jsonHelper";
import { openNotificationWithIcon } from "../../../../_artifact/helpers/tableHelper";

const initialManageState = {
  loader: false,
  managepanelistdata: {},
  specialistDDL: [],
};

// Slice
export const manageSlice = createSlice({
  name: "manageSlice",
  initialState: initialManageState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.loader = false;
      toast.error(action.payload.error.clientMessage);
    },
    startCall: (state, action) => {
      state.error = null;
      state.loader = true;
    },
    specialistDDLFetched: (state, action) => {
      const { specialistDDL } = action.payload;
      state.loader = false;
      state.error = null;
      state.specialistDDL = specialistDDL;
    },
    // findResults
    specManageFetched: (state, action) => {
      const { managepanelistdata } = action.payload;
      state.loader = false;
      state.error = null;
      state.managepanelistdata = managepanelistdata;
    },
    panelistManageSet: (state, action) => {
      const { Panelists } = action.payload;
      state.loader = false;
      state.error = null;
      state.managepanelistdata.Panelists = Panelists;
    },
    specialistUpdated: (state, action) => {
      state.loader = false;
      state.error = null;
    },
  },
});

// Actions
const { actions } = manageSlice;

export const updateError = (error) => (dispatch) =>
  dispatch(actions.catchError({ error }));

export const fetchSpecialistDDL = () => (dispatch) => {
  dispatch(actions.startCall());
  return getSpecialistDDL()
    .then((response) => {
      dispatch(actions.specialistDDLFetched({ specialistDDL: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch specialist DDL";
      dispatch(actions.catchError({ error }));
    });
};

export const fetchPanelistManage = () => (dispatch) => {
  dispatch(actions.startCall());
  return managePanelist()
    .then((response) => {
      const managepanelistdata = response.data;
      dispatch(actions.specManageFetched({ managepanelistdata }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find results";
      dispatch(actions.catchError({ error }));
    });
};

export const editAdmin = (index, row) => (dispatch, getState) => {
  console.log("Data", row);
  let newData = getState().manageData.managepanelistdata;
  newData = newData.Panelists.map((val) => {
    if (val.panelist_id === row.panelist_id) {
      return {
        ...val,
        status: row.status,
        status_comment: row.inactivereason,
      };
    } else {
      return {
        ...val,
      };
    }
  });

  var bodyFormData = new FormData();
  bodyFormData.append("panel_id", row.panel_id);
  bodyFormData.append("panelist_id", row.panelist_id);
  bodyFormData.append("status", row.status);
  bodyFormData.append("status_comment", row.inactivereason);

  dispatch(actions.startCall());
  return editAdminUrl(bodyFormData)
    .then((response) => {
      const Panelists = newData;
      openNotificationWithIcon("success", response.data.panelist);
      dispatch(actions.panelistManageSet({ Panelists }));
    })
    .catch((error, response) => {
      error.clientMessage = "Can't find results";
      openNotificationWithIcon("success", response.data);
      dispatch(actions.catchError({ error }));
    });
};

export const addSpecialist = (body) => (dispatch) => {
  dispatch(actions.startCall());
  return postAddSpecialist(body).then(() =>
    dispatch(actions.specialistUpdated())
  );
};

export const deleteSpecialist = (body) => (dispatch) => {
  dispatch(actions.startCall());
  return postDeleteSpecialist(body).then(() =>
    dispatch(actions.specialistUpdated())
  );
};

// Crud
export const RESULTS_URL = "specialist";

function getSpecialistDDL() {
  return axios.get(`${RESULTS_URL}/addSpecialistDrdn/`);
}

function managePanelist() {
  return axios.get(`${RESULTS_URL}/adminView/`);
}

function editAdminUrl(body) {
  return axios({
    method: "post",
    url: `${RESULTS_URL}/panelistStatusUpdate/`,
    data: body,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

function postAddSpecialist(body) {
  return axios.post(`${RESULTS_URL}/addSpecialist/`, body);
}

function postDeleteSpecialist(body) {
  return axios.put(`${RESULTS_URL}/deleteSpecialist/`, body);
}
