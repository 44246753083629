import * as auth from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use(
    (config) => {
      const authToken = localStorage.getItem("tok");
      if (authToken) {
        config.headers.Authorization = `JWT ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(null, (error) => {
    if (error.response?.status === 403 || error.response?.status === 401) {
      // localStorage.clear();
      // store.dispatch(auth.actions.logout());
      // window.location = "/api/login/loginpage";
      console.log("error", error, error.response, window.location.href);
    } else return Promise.reject(error);
  });
}
