import React from "react";
import { useLang } from "./Artifacti18n";
import { IntlProvider } from "react-intl";

import esMessages from "./messages/es";
import enMessages from "./messages/en";

export const allMessages = {
  en: enMessages,
  es: esMessages,
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
