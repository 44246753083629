// export default [
//   {
//     panelist_id: "argus m_noehledesmav@gmail.com",
//     pet_name: "Argus M",
//   },
//   {
//     panelist_id: "duke_noehledesmav@gmail.com",
//     pet_name: "Duke",
//   },
//   {
//     panelist_id: "emilia_noehledesmav@gmail.com",
//     pet_name: "Emilia",
//     tests: 1,
//   },
// ];

export default {
  panelist_details: {
    panelist_name: "Monica Mejia",
    language: "es",
  },
  panelist_pets: [
    {
      panelist_id: "argus m_noehledesmav@gmail.com",
      pet_name: "Argus M",
      species: "Perro",
    },
    {
      panelist_id: "duke_noehledesmav@gmail.com",
      pet_name: "Duke",
      species: "Perro",
    },
    {
      panelist_id: "emilia_noehledesmav@gmail.com",
      pet_name: "Emilia",
      species: "Perro",
    },
  ],
  currentTestSchedule: [
    {
      panelist_id: "emilia_noehledesmav@gmail.com",
      test_schedule_id: 7,
      test_name: "MDPD-009-M",
      product_id: "Product_1",
      pixel_id: "00PX1581",
      product_weight: 75.0,
    },
    {
      panelist_id: "duke_noehledesmav@gmail.com",
      test_schedule_id: 7,
      test_name: "MDPD-009-M",
      product_id: "Product_2",
      pixel_id: "00PX1581",
      product_weight: 75.0,
    },
    {
      panelist_id: "argus m_noehledesmav@gmail.com",
      test_schedule_id: 7,
      test_name: "MDPD-009-M",
      product_id: "Product_3",
      pixel_id: "00PX1581",
      product_weight: 75.0,
    },
  ],
  upcomingTestSchedule: [
    {
      test_schedule_id: 5,
      test_name: "MDPD-009-M",
      product_id: "Product_3",
      start_date: "2021-11-22 00:00:00",
      end_date: "2021-11-23 00:00:00",
      panelist_id: "argus m_noehledesmav@gmail.com",
    },
  ],
};
